import NavbarComponent from "../components/navbar2";
import Invitation from "../components/invitation";

const InvitationStatus = () => {
  return (
    <div className="bg-yellow h-100vh d-flex flex-column position-relative">
      <NavbarComponent />
      <Invitation />
    </div>
  );
};

export default InvitationStatus;
