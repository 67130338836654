import React, { createContext, useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getInvitationWithStatusById } from "../api/api";
import { useDispatch } from "react-redux";
// import { setInvitationWithUsers } from "../redux-slice/statusSlice";

// Create the Context
const PDFContext = createContext();

// Create a Provider Component
export const PDFProvider = ({ children }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [pdfData, setPdfData] = useState("");
  const [isUploadExcel, setIsUploadExcel] = useState(false);
  const [alternateNumber, setAlternateNumber] = useState(null);

  // const [invitationId, setInvitationId] = useState();
  // const [short_id, setShort_id] = useState();

  // useEffect(() => {
  //   const fetchInvitation = async () => {
  //     if (short_id) {
  //       try {
  //         const invitation = await getInvitationWithStatusById(short_id);
  //         if (invitation.data.result) {
  //           setInvitationId(invitation.data.result._id);
  //           dispatch(setInvitationWithUsers(invitation.data.result));
  //         }
  //       } catch (error) {
  //         console.error("Error fetching invitation:", error);
  //       }
  //     }
  //   };

  //   fetchInvitation();
  // }, [short_id]);

  return (
    <PDFContext.Provider
      value={{
        pdfData,
        setPdfData,
        isUploadExcel,
        setIsUploadExcel,
        alternateNumber,
        setAlternateNumber,
        // invitationId,
        // setInvitationId,
        // short_id,
        // setShort_id,
      }}
    >
      {children}
    </PDFContext.Provider>
  );
};

// Custom Hook to use PDFContext
export const usePDFContext = () => {
  return useContext(PDFContext);
};
