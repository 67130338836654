import React from "react";
import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";

const TermsPage = () => {
  return (
    <div style={{ paddingTop: "76px"}}>
      <Navbar />
      <div class="container mt-5" style={{ paddingBottom: "76px"}}>
        <h1 className="h1">Terms & Conditions</h1>
        <p>Last Updated: 31st Jan 2024.</p>
        
        <h2 className="h3">1. Introduction</h2>
        <p>Welcome to Rapid Sender. By using our platform, you agree to comply with and be bound by the following terms and conditions.</p>
        
        <h2 className="h3">2. User Accounts</h2>
        <p>To access certain features, you must create an account. You are responsible for maintaining the confidentiality of your login credentials.</p>
        
        <h2 className="h3">3. Acceptable Use</h2>
        <p>You agree to use Rapid Sender only for lawful purposes. You must not misuse our platform to send spam, offensive, or fraudulent messages.</p>
        
        <h2 className="h3">4. Payments & Refunds</h2>
        <p>All payments for premium features are final and non-refundable unless otherwise specified. We reserve the right to modify pricing at any time.</p>
        
        <h2 className="h3">5. Limitation of Liability</h2>
        <p>Rapid Sender shall not be liable for any indirect, incidental, or consequential damages resulting from the use or inability to use our platform.</p>
        
        <h2 className="h3">6. Termination</h2>
        <p>We reserve the right to terminate or suspend your account if you violate these Terms & Conditions.</p>
        
        <h2 className="h3">7. Changes to Terms</h2>
        <p>We may update these Terms & Conditions at any time. Continued use of our platform after changes implies acceptance of the updated terms.</p>
        
        <h2 className="h3">8. Contact Us</h2>
        <p>If you have any questions regarding these Terms & Conditions, please contact us:</p>
        <ul>
            <li>Email: <a href="mailto:rapid.sender.56@gmail.com
">rapid.sender.56@gmail.com
</a></li>
            <li>Phone: +91 96248 28267</li>
            <li>Address: Surat, Gujarat, India</li>
        </ul>
      </div>
      {/* <CallToAction /> */}
      <Footer />
    </div>
  );
};
export default TermsPage;
