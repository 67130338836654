import React from "react";

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-5">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-4">
            <h4 className="h5 mb-3">
              <i className="fas fa-envelope-open-text text-warning me-2"></i>
              RapidSender
            </h4>
            <p className="mb-4">
              Send your personalized invitation via whatsapp. Perfect for all your special occasions.
            </p>
            <h5 className="mb-3">Follow Us</h5>
            <div className="d-flex gap-3">
              <a href="#" className="text-light"><i className="fab fa-facebook"></i></a>
              <a href="#" className="text-light"><i className="fab fa-twitter"></i></a>
              <a href="#" className="text-light"><i className="fab fa-instagram"></i></a>
              <a href="#" className="text-light"><i className="fab fa-linkedin"></i></a>
            </div>

          </div>
          <div className="col-6 col-lg-2">
            <h5 className="mb-3">Quick Links</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="/" className="text-light text-decoration-none">Home</a>
              </li>
              <li className="mb-2">
                <a href="/#features" className="text-light text-decoration-none">Features</a>
              </li>
              <li className="mb-2">
                <a href="/#samples" className="text-light text-decoration-none">Templates</a>
              </li>
              <li className="mb-2">
                <a href="/#pricing" className="text-light text-decoration-none">Pricing</a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-3">
            <h5 className="mb-3">Contact</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <i className="fas fa-envelope text-warning me-2"></i>
                rapid.sender.56@gmail.com

              </li>
              <li className="mb-2" href="tel:919726181166">
                <i className="fas fa-phone text-warning me-2"></i>
                +91 96248 28267
              </li>
              <li className="mb-2">
                <i className="fas fa-map-marker-alt text-warning me-2"></i>
                Gujarat, India
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <h5 className="mb-3">Support</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <a href="/terms" className="text-light text-decoration-none">Terms & Conditions</a>
              </li>
              <li className="mb-2">
                <a href="/privacy" className="text-light text-decoration-none">Privacy Policy</a>
              </li>
              <li className="mb-2">
                <a href="/cancellation" className="text-light text-decoration-none">Refund Policy</a>
              </li>
              <li className="mb-2">
                <a href="/contact" className="text-light text-decoration-none">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-4" />
        <div className="text-center">
          <p className="mb-0">&copy; 2025 RapidSender. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
