import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import "./index.scss";
import UploadFile from "../uploadFile";
import { left_arrow, right_arrow } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  removeEmptyRows,
  validateExcel,
  validateString as vs,
} from "../../utils";
import ExcelModel from "../excelModel";
import { getFileStreamFromIndexDBAPI } from "../../indexDB/getFromIndexDB";
import { setExcelData } from "../../redux-slice/filesSlice";
import { usePDFContext } from "../../context/Context";
import strokeImage from "../../assets/images/stroke-yellow-hero.png"

const CreateInvitation = () => {
  const { pdfData, setPdfData, isUploadExcel, setIsUploadExcel } =
    usePDFContext();
  const [fileStream, setFileStream] = useState("");
  // const [pdfData, setPdfData] = useState("");
  // const [isUploadExcel, setIsUploadExcel] = useState(false);
  const [excelModalShow, setExcelModalShow] = useState(false);
  const [incompleteRows, setIncompleteRows] = useState("");
  const { excelData, isBackFromVariable, fileType, isAddingManually } =
    useSelector((state) => state.files);
  const navigate = useNavigate();

  useEffect(() => {
    getFileStreamFromIndexDB("pdfFile");
    getFileStreamFromIndexDB("imageFile");
  }, [fileType, pdfData]);

  const getFileStreamFromIndexDB = async (fileType) => {
    try {
      const imageFile = await getFileStreamFromIndexDBAPI(fileType);

      if (imageFile?.imageStream) {
        setFileStream(imageFile.imageStream);
      }
      if (imageFile?.pdfStream) {
        setPdfData(imageFile.pdfStream);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();

  const handleRemoveEmptyRows = () => {
    const clonedExcelData = JSON.parse(JSON.stringify(excelData));
    if (clonedExcelData?.guestData?.length > 1) {
      const filteredGuestData = removeEmptyRows(clonedExcelData.guestData);
      clonedExcelData.guestData = filteredGuestData;
      dispatch(setExcelData(clonedExcelData));
      return clonedExcelData?.guestData?.length > 0
        ? clonedExcelData.guestData
        : [];
    } else {
      return clonedExcelData?.guestData;
    }
  };

  const handleValidateExcel = () => {
    const guestData = handleRemoveEmptyRows();
    const incompleteRows_ =
      guestData.length > 0 ? validateExcel(guestData) : "";
    if (vs(String(incompleteRows_))) {
      setIncompleteRows(incompleteRows_);
      setExcelModalShow(true);
    } else {
      navigate("/variable");
    }
  };

  // useEffect(() => {
  //   if (isBackFromVariable) {
  //     setIsUploadExcel(excelData ? true : false);
  //   }
  // }, [isBackFromVariable]);

  useEffect(() => {
    if (excelData?.guestData && !isAddingManually) {
      handleValidateExcel();
    }
  }, [excelData, isAddingManually]);

  return (
    <>
      <ExcelModel
        show={excelModalShow}
        onHide={() => setExcelModalShow(false)}
        incompleteRows={incompleteRows}
      />
      <div className="flex-grow-1 d-flex align-items-center justify-content-center py-10" style={{backgroundImage: `url(${strokeImage})`,backgroundPosition: "center"}}>
        <Container className="create-invitation-wrapper d-flex flex-column align-items-center ">
          <div
            className={`d-flex justify-content-${
              isUploadExcel ? "between" : "center"
            }  py-20 mt-20 w-784`}
          >
            {isUploadExcel && (
              <Button
                variant="outline-secondary"
                className="back-button fs-25 text-dark"
                onClick={() => setIsUploadExcel(false)}
              >
                {left_arrow()} Back
              </Button>
            )}
            <p className="d-flex align-items-center fs-50 fw-800 mb-0 text-center text-title">
              {isUploadExcel ? "Upload your excel" : "Create an Invitation"}
            </p>
          </div>
          <div className="w-784 h-100">
            <div className="upload-file-wrapper">
              <UploadFile isUploadExcel={isUploadExcel} />
            </div>
            <div className="d-flex justify-content-between pt-20 next-button-wrapper">
              <p className="mb-0  w-100 fs-22 fw-400">
                {isUploadExcel
                  ? "- Upload your data in form of excel or csv."
                  : "- Upload your graphic, image or invitation."}
              </p>
              {(fileStream || pdfData) && (
                <Button
                  variant="primary"
                  className="next-button fs-25 d-flex justify-content-between align-items-center"
                  onClick={() => {
                    !isUploadExcel && setIsUploadExcel(true);
                    {
                      isUploadExcel && excelData && handleValidateExcel();
                    }
                  }}
                >
                  Next {right_arrow("36px", "36px")}
                </Button>
              )}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CreateInvitation;
