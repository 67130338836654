import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  textMessage: "",
  isShowMessageButtons: true,
  invitationTitle: "",
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setTextMessage: (state, action) => {
      state.textMessage = action.payload;
    },
    setIsShowMessageButtons: (state, action) => {
      state.isShowMessageButtons = action.payload;
    },
    setInvitationTitle: (state, action) => {
      state.invitationTitle = action.payload;
    },
  },
});














export const { setTextMessage, setIsShowMessageButtons, setInvitationTitle } = messageSlice.actions;

export default messageSlice.reducer;
