import React, { useMemo, useRef, useState } from "react";
import { getColorInMultipleFormate } from "../../utils/color";
import "./index.scss";

const ColorPicker = (props) => {
  const { onColorChange, color } = props;

  const colorPickerRef = useRef();

  const handleColorChange = (e) => {
    const hexValue = e.target.value;
    const colorInMultipleFormate = getColorInMultipleFormate(hexValue);
    onColorChange(colorInMultipleFormate);
  };

  return (
    <div
      className="color-picker-wrapper"
      onClick={() => {
        colorPickerRef.current.click();
      }}
      style={{ backgroundColor: color }}
    >
      <input
        className="color-picker"
        type="color"
        defaultValue={color}
        onChange={(e) => {
          handleColorChange(e);
        }}
        ref={colorPickerRef}
        // hidden
      />
    </div>
  );
};

export default ColorPicker;
