import React from "react";

const HowItWorksSection = () => {
  return (
    <section id="how-it-works" className="py-5 bg-light">
      <div className="container py-5">
        <div className="text-center mb-5">
          <span className="badge bg-primary mb-3 px-3 py-2 rounded-pill">
            <i className="fas fa-magic me-2"></i>Process
          </span>
          <h2 className="display-5 fw-bold mb-3">How It Works</h2>
          <p className="lead text-muted mx-auto" style={{ maxWidth: "600px" }}>
            Three simple steps to start sending your WhatsApp invitations.
          </p>
        </div>
        <div className="row g-4">
          <div className="col-md-4">
            <div className="feature-card p-4">
              <div className="feature-icon">
                <i className="fas fa-1"></i>
              </div>
              <h3 className="h4 mb-3 text-center">Upload Contacts</h3>
              <p className="text-muted text-center">
                Import your guest list from Excel or CSV file with phone numbers.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-card p-4">
              <div className="feature-icon">
                <i className="fas fa-2"></i>
              </div>
              <h3 className="h4 mb-3 text-center">Create Message</h3>
              <p className="text-muted text-center">
                Design your invitation message with our template builder.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-card p-4">
              <div className="feature-icon">
                <i className="fas fa-3"></i>
              </div>
              <h3 className="h4 mb-3 text-center">Send & Track</h3>
              <p className="text-muted text-center">
                Launch your campaign and monitor responses in real-time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
