import { ref, set, onValue } from 'firebase/database';
import { database } from '../config/firebase';

// Function to update QR code
export const updateQRCode = async (invitationId, qrCodeData) => {
  try {
    const qrCodeRef = ref(database, `invitations/${invitationId}/qrCode`);
    await set(qrCodeRef, qrCodeData);
    return true;
  } catch (error) {
    console.error('Error updating QR code:', error);
    return false;
  }
};

// Function to listen to QR code changes
export const listenToQRCode = (invitationId, callback) => {
  const qrCodeRef = ref(database, `invitations/${invitationId}/qrCode`);
  
  onValue(qrCodeRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
  });
};

export const listenToInvitationData = (invitationId, callback) => {
  const invitationRef = ref(database, `invitations/${invitationId}`);
  onValue(invitationRef, (snapshot) => {
    const data = snapshot.val();
    callback(data);
  });
};
