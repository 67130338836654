import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GuestData from "../../guestData";
import { setExcelData } from "../../../redux-slice/filesSlice";
import "./index.scss";
import { Badge, Button, Modal } from "react-bootstrap";
import { left_arrow } from "../../../assets/icons";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  convertExcelToJson,
  copyTextToClipboard,
  exportExcelFile,
} from "../../../utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap/esm";
import QRCode from "qrcode.react";
import whatsapp_icon from "../../../assets/logo/whatsapp-logo.png";
import { WaitingMessage } from "../../customer/waitingMessage";
// import { socket } from "../../../config/socket.config";
// import { socket } from "../../../config/socket.config";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import {
  getCustomDetails,
  updateInvitationMessage,
  updateUsers,
} from "../../../api/api";
import { toast } from "react-hot-toast";
import { setInvitationWithUsers } from "../../../redux-slice/statusSlice";
import SuccessMessage from "../../successMessage";
import whatsapp from "../../../assets/logo/whatsapp.png";
import { Helmet } from "react-helmet";
import { usePDFContext } from "../../../context/Context";
import {
  setInvitationTitle,
  setTextMessage,
} from "../../../redux-slice/messageSlice";
import { getDatabase, onValue, ref, update } from "firebase/database";
import { database } from "../../../config/firebase";

const Status = (props) => {
  const {
    invitationWithUsers,
    qrCode,
    user,
    currentRow,
    newExcelData,
    screenshot = "",
    qrCodeScanned,
  } = props;
  const _inputProps = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [
      ".xltx",
    ],
    "application/vnd.ms-excel-template": [".xlt"],
    "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
    "text/csv": [".csv"],
    "text/tab-separated-values": [".tab"],
  };

  const params = useParams();
  // const { invitationId } = usePDFContext();
  const invitationId = params?.invitationId;
  const { alternateNumber, setAlternateNumber } = usePDFContext();
  const { textMessage, invitationTitle } = useSelector(
    (state) => state.message
  );

  const [customDetails, setCustomDetails] = useState();
  const [loginedUsers, setLoginedUsers] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [alternateNumber, setAlternateNumber] = useState(null);
  const [isUpdateMessageModalOpen, setIsUpdateMessageModalOpen] =
    useState(false);

  useEffect(() => {
    const database = getDatabase();
    const invitationRef = ref(
      database,
      `invitations/${invitationId}/loginedUsers`
    );

    // Listen for data changes
    onValue(invitationRef, (snapshot) => {
      if (snapshot.exists()) {
        setLoginedUsers(snapshot.val());
      } else {
        console.log("No data available");
      }
    });
  }, [invitationId]);

  useEffect(() => {
    const fetchCustomDetails = async () => {
      try {
        if (invitationId) {
          const customDetails = await getCustomDetails(invitationId);
          setCustomDetails(customDetails?.details || {});
        }
      } catch (err) {
        console.error("Error fetching custom details:", err);
      }
    };

    fetchCustomDetails();
  }, [invitationId]);

  const { excelData } = useSelector((state) => state.files);

  const {
    isShowImportExportButtons,
    isShowWaitingMessage,
    isShowSuccessMessage,
  } = useSelector((state) => state.status);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const { guestData } = await convertExcelToJson(acceptedFiles[0]);
    await updateUsersViaExcel(guestData);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: _inputProps,
  });

  // const handleBeforeUnload = () => {
  //   socket.emit("logout", invitationId);
  // };
  // const handleBeforeUnload = () => {
  //   socket.emit("logout", invitationId);
  // };

  // const handlePopstate = () => {
  //   handleBeforeUnload();
  // };
  // const handlePopstate = () => {
  //   handleBeforeUnload();
  // };

  // window.addEventListener("beforeunload", handleBeforeUnload);
  // window.removeEventListener("popstate", handlePopstate);
  // window.addEventListener("beforeunload", handleBeforeUnload);
  // window.removeEventListener("popstate", handlePopstate);

  // const initializeSocket = (socket) => {
  //   socket.emit("joinRoom", invitationId);
  // };
  // const initializeSocket = (socket) => {
  //   socket.emit("joinRoom", invitationId);
  // };

  // const joinRoom = () => {
  //   socket.emit("joinRoom", invitationId);
  // };
  // const joinRoom = () => {
  //   socket.emit("joinRoom", invitationId);
  // };

  // const leaveRoom = () => {
  //   socket.emit("leaveRoom", invitationId);
  // };
  // const leaveRoom = () => {
  //   socket.emit("leaveRoom", invitationId);
  // };

  const createExcelHeaders = (users) => {
    if (users?.length > 0) {
      const headers = Object.keys(users[0]);
      return headers;
    }
  };

  const filterExcelData = (users) => {
    if (users?.length > 0) {
      const filteredData =
        users?.length > 0 &&
        users.map((user, i) => {
          return {
            ...user.details,
            _id: user._id,
            userId: user._id,
            status: user.status,
          };
        });
      return filteredData;
    }
  };

  // const filterExcelData = (users) => {
  //   if (users?.length > 0) {
  //     const filteredData = users.map((user) => {
  //       const { _id, ...detailsWithoutId } = user.details; // Exclude _id from user.details
  //       return {
  //         ...detailsWithoutId, // Spread the rest of the details
  //         _id: user._id, // Use the _id from the user object
  //         status: user.status,
  //       };
  //     });
  //     return filteredData;
  //   }
  // };

  const updateExcelData = (invitationWithUsers) => {
    const guestData = filterExcelData(invitationWithUsers.users);

    const excelHeaders = createExcelHeaders(guestData);
    const excelData = {
      guestData,
      excelHeaders,
    };
    // console.log("excelData---UserASDFas",excelData);
    dispatch(setExcelData(excelData));
  };

  const updateUsersViaExcel = async (users) => {
    let clonedExcelData = JSON.parse(JSON.stringify(excelData));
    let clonedGuestData = clonedExcelData?.guestData;
    const usersStatus = await updateUsers(users);
    if (usersStatus?.length > 0) {
      const updatedUsers = usersStatus.filter((user) => {
        const updateStatus = user?.value;
        return (
          user?.status === "fulfilled" &&
          updateStatus?.isUpdated &&
          updateStatus
        );
      });

      let notUpdatedUsers = usersStatus.filter((user) => {
        const updateStatus = user?.value;
        return user?.status === "rejected" && updateStatus;
      });

      if (updatedUsers.length > 0) {
        for (let i = 0; i < updatedUsers.length; i++) {
          const user = updatedUsers[i].value;
          const _id = user._id;
          const index = clonedGuestData.findIndex((guest) => guest._id === _id);
          let guest = clonedGuestData[index];
          guest = {
            ...guest,
            ...user?.updatedUser,
            status: "pending",
          };
          delete guest.isUpdating;
          clonedGuestData = [
            ...clonedGuestData.slice(0, index),
            guest,
            ...clonedGuestData.slice(index + 1),
          ];
        }
        updateInvitationWithUsers(clonedGuestData);
        clonedExcelData.guestData = clonedGuestData;
        dispatch(setExcelData(clonedExcelData));
        toast.success("Guest details updated!");
      }
      notUpdatedUsers.length > 0 && toast.error("Try again after someTime");
    }
  };

  const updateInvitationWithUsers = (guests) => {
    const clonedGuestData = JSON.parse(JSON.stringify(guests));
    const clonedInvitationWithUsers = JSON.parse(
      JSON.stringify(invitationWithUsers)
    );

    const updatedUsers = clonedInvitationWithUsers?.users?.map((user, i) => {
      const guest_ = clonedGuestData.find((guest) => guest?._id === user?._id);
      const status = guest_?.status;
      delete guest_?._id;
      delete guest_?.status;
      delete guest_?.isUpdating;
      return {
        ...user,
        details: guest_,
        status: status,
      };
    });
    clonedInvitationWithUsers.users = updatedUsers;
    dispatch(setInvitationWithUsers(clonedInvitationWithUsers));
  };

  // useEffect(() => {
  //   if (invitationId) initializeSocket(socket);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("popstate", handlePopstate);
  //     // socket.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (invitationWithUsers) {
      updateExcelData(invitationWithUsers);
    }
  }, [invitationWithUsers]);

  useEffect(() => {
    if (customDetails) {
      window.document.title = `Invitation for ${customDetails?.name}`;

      const ogTitleMeta = window.document.querySelector(
        "meta[property='og:title']"
      );
      if (ogTitleMeta) {
        ogTitleMeta.setAttribute(
          "content",
          `Invitation for ${customDetails?.name}`
        );
      }

      const twitterTitleMeta = window.document.querySelector(
        "meta[name='twitter:title']"
      );
      if (twitterTitleMeta) {
        twitterTitleMeta.setAttribute(
          "content",
          `Invitation for ${customDetails?.name}`
        );
      }
    }
  }, [customDetails]);

  const handleUpdateMessage = () => {
    navigate("/invitation-message", {
      replace: true,
      state: { isUpdateMessage: true, invitationId: invitationId },
    });
    // setIsUpdateMessageModalOpen(true);
  };

  // const handleCancelUpdateMessage = () => {
  //   setIsUpdateMessageModalOpen(false);
  // };

  // const handleUpdateWhatsappMessage = async () => {
  //   const payload = {
  //     invitation_id: invitationId,
  //     field: {
  //       invitationTitle: invitationTitle,
  //       message: textMessage,
  //     },
  //   };

  //   console.log("payload", payload);

  //   try {
  //     const response = await updateInvitationMessage(payload);

  //     if (response) {
  //       toast.success("Message updated successfully");
  //       setIsUpdateMessageModalOpen(false);
  //     } else {
  //       throw new Error(response?.message || "Failed to update the message.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating WhatsApp message:", error);
  //     toast.error(error.message || "An unexpected error occurred.");
  //   }
  // };

  const handleSaveAlternateNumber = async () => {
    try {
      const payload = {
        invitation_id: invitationId,
        field: {
          alternateNumber: Number(alternateNumber),
        },
      };
      const updatedInvitation = await updateInvitationMessage(payload);
      if (updatedInvitation) {
        toast.success("Alternate number saved successfully!");
        setIsModalOpen(false);
      }
    } catch (error) {
      setIsModalOpen(false);
      console.error("Error saving alternate number:", error);
      toast.error("Failed to save alternate number. Please try again.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAlternateNumber = () => {
    setIsModalOpen(true);
  };

  const handleLogout = async () => {
    try {
      const payload = {
        invitation_id: invitationId,
        field: {
          process: 0,
        },
      };
      const updatedInvitation = await updateInvitationMessage(payload);
      if (updatedInvitation) {
        const invitationRef = ref(database, `invitations/${invitationId}`);
        const processRef = ref(database, `invitations/${invitationId}/process`);
        try {
          // Update sending status
          await update(invitationRef, {
            sendingStatus: "stopped",
          });

          // Update process fields: isStop, isPause, and isSend
          await update(processRef, {
            isStop: true,
            isPause: false,
            isSend: false,
          });

          console.log("::::stopMessageSending: Fields updated successfully");
        } catch (error) {
          console.error("Error updating fields:", error);
        }

        toast.success("Logged out successfully!");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <>
      {/* <Helmet>
        <title>Invitation for {customDetails?.name}</title>
        <meta
          property="og:title"
          content={`Invitation for ${customDetails?.name}`}
        />
      </Helmet> */}
      {
        // isShowWaitingMessage ? (
        //   <div className="p-20 w-100 d-flex justify-content-center align-items-center h-100">
        //     <WaitingMessage />
        //   </div>
        // ) : isShowSuccessMessage ? (
        //   <div className="p-20 w-100 d-flex justify-content-center align-items-center h-100">
        //     <SuccessMessage id={invitationId} />
        //   </div>
        // ) : (
        excelData && (
          <div className="mx-auto excel-data-wrapper">
            <div className="d-flex justify-content-between excel-buttons-wrapper">
              <div className="me-1">
                <Button
                  variant="light"
                  className="d-flex align-items-center justify-content-evenly mb-2"
                  onClick={() => {
                    navigate("/variable", { replace: true });
                    // handleBeforeUnload();
                  }}
                >
                  {left_arrow()}
                </Button>
              </div>
              <div className="d-flex justify-content-center">
                <div className="mb-2 bg-white py-1 px-3 d-flex align-items-center justify-content-around rounded-3 gap-3 invitation-status-wrapper w-fit-content">
                  <div className="d-flex align-items-center">
                    <p className="mb-0 invitation-id-text">
                      <span className="fw-500">Invitation Id :</span>
                      {invitationId}
                    </p>
                    <OverlayTrigger
                      placement="top"
                      className=""
                      overlay={<Tooltip id="button-tooltip-2">Copy</Tooltip>}
                    >
                      <p
                        className="ms-2 mb-0 cursor-pointer"
                        onClick={() => {
                          copyTextToClipboard(invitationId);
                        }}
                      >
                        &#128203;
                      </p>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>

              {newExcelData?.values.length > 0 && (
                <div className="d-flex">
                  <div className="me-1">
                    <Button
                      variant="primary"
                      className="data-button"
                      size="sm"
                      onClick={handleAlternateNumber}
                    >
                      Alternate number
                    </Button>
                  </div>
                  <div className="me-1">
                    <Button
                      variant="primary"
                      className="data-button"
                      size="sm"
                      onClick={handleUpdateMessage}
                    >
                      Update Message
                    </Button>
                  </div>

                  {loginedUsers && (
                    <div className="me-1">
                      <Button
                        variant="primary"
                        className="data-button"
                        size="sm"
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    </div>
                  )}
                  {/* <div
                    {...getRootProps()}
                    className="me-1"
                    id="upload-file-dropzone"
                  >
                    <input {...getInputProps()} hidden />
                    <Button
                      variant="primary"
                      className="data-button"
                      size="sm"
                      onClick={updateUsersViaExcel}
                    >
                      Import
                    </Button>
                  </div> */}
                  <div className="me-1">
                    <Button
                      variant="dark"
                      size="sm"
                      className="data-button"
                      onClick={() => {
                        exportExcelFile(
                          newExcelData.headers,
                          newExcelData.values
                        );
                      }}
                    >
                      Export
                    </Button>
                  </div>

                  {isModalOpen && (
                    <Modal
                      show={isModalOpen}
                      onHide={handleCancel}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Enter Alternate Mobile Number
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <input
                          type="number"
                          value={alternateNumber}
                          onChange={(e) => setAlternateNumber(e.target.value)}
                          placeholder="Alternate Mobile Number"
                          className="form-control"
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button onClick={handleSaveAlternateNumber}>
                          Save
                        </Button>
                        <Button onClick={handleCancel}>Cancel</Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              )}
            </div>

            {qrCode?.string && qrCodeScanned ? (
              <div className="d-flex justify-content-center align-items-center">
                <div className="qr-code-skeleton mt-2 mb-2">
                  <div className="qr-code-container">
                    <div className="qr-code-background d-flex flex-column justify-content-center align-items-center">
                      <img src={whatsapp} alt="RAPID SENDER – Digital Invitation Sending Platform" height={50} />
                      <p className="mt-2" style={{ color: "#808080" }}>
                        whatsapp
                      </p>
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <div className="loader"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              qrCode?.string && (
                <div className="qr-code-wrapper mx-auto d-flex mb-2">
                  <QRCode size={240} value={qrCode.string} />
                </div>
              )
            )}

            {/* {qrCode?.string ? (
              <div className="qr-code-wrapper mx-auto d-flex mb-2">
                {qrCode ? <QRCode size={240} value={qrCode.string} /> : ""}
              </div>
            ) : (
              ""
            )}
            { qrCode?.string ? (
              <div class="qr-code-skeleton mt-2 mb-2">
                <div class="qr-code-container">
                  <div class="qr-code-background"></div>
                  <div class="qr-code-shimmer"></div>
                </div>
              </div>
            ) : (
              ""
            )} */}
            <div className="invitation-status-wrapper custom-scrollbar table-wrapper">
              <GuestData
                currentRow={currentRow}
                invitationWithUsers={invitationWithUsers}
              />
            </div>

            {/* {isUpdateMessageModalOpen && (
              <Modal
                show={isUpdateMessageModalOpen}
                onHide={handleCancelUpdateMessage}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Update Invitation Message
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>
                    {excelData?.excelHeaders?.length > 0 &&
                      excelData?.excelHeaders?.map((variable, i) => {
                        return (
                          <Badge
                            pill
                            bg="primary"
                            key={`variable-${i}`}
                            className="me-1 cursor-pointer fw-500"
                            // onClick={() => addToTextArea(`{{${variable}}}`)}
                          >
                            {variable}
                          </Badge>
                        );
                      })}

                    <input
                      type="text"
                      value={invitationTitle}
                      onChange={(e) => {
                        dispatch(setInvitationTitle(e.target.value));
                      }}
                      placeholder="Invitation Card Name"
                      className="form-control mb-3"
                    />
                    <textarea
                      value={textMessage}
                      onChange={(e) => {
                        dispatch(setTextMessage(e.target.value));
                      }}
                      placeholder="Write a Message"
                      className="form-control"
                      rows="4"
                    />
                  </>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={handleUpdateWhatsappMessage}>Update</Button>
                  <Button onClick={handleCancelUpdateMessage}>Cancel</Button>
                </Modal.Footer>
              </Modal>
            )} */}
          </div>
        )
      }
    </>
  );
};

export default Status;
