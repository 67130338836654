import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/fonts/index.css";
import App from "./App";
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Loader from "./components/loader";
import { PDFProvider } from "./context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <PDFProvider>
        <App />
      </PDFProvider>
    </PersistGate>
  </Provider>
);
