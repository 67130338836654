import React, { useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Tesseract from "tesseract.js";
import NavbarComponent from "../components/navbar2";
import strokeImage from "../assets/images/stroke-yellow-hero.png";
import { cancel_icon, excel_icon, export_icon } from "../assets/icons";

const OCRPage = () => {
    const [image, setImage] = useState(null);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    
    const uploadImageRef = useRef(null);
    const handleClick = () => {
        if (uploadImageRef.current) {
            uploadImageRef.current.value = null; // Reset file input
            setImage(null); // Clear the current image preview
            uploadImageRef.current.click(); // Open file picker
        }
    };
    
    // Handle file upload
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(URL.createObjectURL(file));
            setText(""); // Reset text
        }
    };
    
    // Perform OCR on uploaded image
    const handleOCR = () => {
        if (!image) return alert("Please upload an image first.");
        
        setLoading(true);
        setProgress(0);
        
        Tesseract.recognize(image, "eng+guj", {
            logger: (m) => {
                if (m.status === "recognizing text") {
                    setProgress(parseInt(m.progress * 100));
                }
            },
        })
        .then(({ data: { text } }) => {
            setText(text);
        })
        .catch((error) => console.error("OCR Error: ", error))
        .finally(() => setLoading(false));
    };
    
    
    const UploadButton = ({ txt }) => {
        return (
            <Button
                variant="light"
                className="d-flex align-items-center justify-content-center upload-data-button w-auto"
                onClick={handleClick}
            >
                {export_icon(40, 40)}
                <span className="ps-2 fs-16 fw-500">{txt}</span>
            </Button>
        );
    };
    

    return (
        <div className="bg-yellow h-100vh d-flex flex-column position-relative">
      <NavbarComponent />
      <div
        className="flex-grow-1 d-flex align-items-center justify-content-center py-10"
        style={{
          backgroundImage: `url(${strokeImage})`,
          backgroundPosition: "center",
        }}
      >
        <Container className="create-invitation-wrapper d-flex flex-column align-items-center ">
          <div
            className={`d-flex justify-content-${
              "between"
              // isUploadExcel ? "between" : "center"
            }  py-20 mt-20 w-784`}
          >
            <h1 className="d-flex align-items-center fs-50 fw-800 mb-0 text-center text-title">
              OCR Image to Text
            </h1>
          </div>
          <div className="w-784 h-100">
            <div className="my-2 overflow-auto custom-scrollbar">
                {loading && (
                  <p className="mt-3 text-gray-600">Progress: {progress}%</p>
                )}
                {text && (
                    <div className="mt-6 p-4 bg-gray-100 shadow-md rounded-lg text-left">
                      <h2 className="text-lg font-semibold mb-2">Extracted Text:</h2>
                      <pre className="whitespace-pre-wrap text-gray-800">{text}</pre>
                    </div>
                  )}
                  {/* <EditableTable /> */}
                </div>
            <div className="upload-file-wrapper">
              <div className="d-flex flex-column  h-100 w-100 p-15">
                <div className="d-flex justify-content-start flex-column">
                  {image ? (
                    <>
                      <img
                        src={image}
                        alt="Uploaded Preview"
                        className="max-w-xs mx-auto mb-4 rounded-lg shadow-md w-50"
                      />
                      <div className="d-flex justify-content-center">
                        <Button
                          variant="light"
                          className={`d-flex align-items-center ms-2 justify-content-center upload-data-button`}
                          onClick={()=>{setImage(null);setText("")}}
                          disabled={loading}
                        >
                          {/* <img src={excel_icon} alt="RAPID SENDER – Digital Invitation Sending Platform" /> */}
                          {cancel_icon(25, 25)}
                          <span className={`ps-2 fs-16  fw-500`}>
                          Cancle
                          </span>
                        </Button>
                        <Button
                          variant="light"
                          className={`d-flex align-items-center ms-2 justify-content-center upload-data-button`}
                          onClick={handleOCR}
                          disabled={loading}
                        >
                          {/* <img src={excel_icon} alt="RAPID SENDER – Digital Invitation Sending Platform" /> */}
                          {excel_icon()}
                          <span className={`ps-2 fs-16  fw-500 text-success`}>
                          {loading ? "Processing..." : "Export Excel"}
                          </span>
                        </Button>
                        
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <UploadButton txt="Upload the Image"/>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={uploadImageRef}
                        className="d-none"
                      />
                    </div>
                  )}
                </div>

                {/* <hr /> */}
                {/* <div className="my-2 overflow-auto custom-scrollbar"> */}
                {/* {loading && (
                  <p className="mt-3 text-gray-600">Progress: {progress}%</p>
                )}
                {text && (
                    <div className="mt-6 p-4 bg-gray-100 shadow-md rounded-lg text-left">
                      <h2 className="text-lg font-semibold mb-2">Extracted Text:</h2>
                      <pre className="whitespace-pre-wrap text-gray-800">{text}</pre>
                    </div>
                  )} */}
                  {/* <EditableTable /> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default OCRPage;
