import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./index.scss";
import { Button, Container } from "react-bootstrap";
import { left_arrow, play_icon, plus_icon } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import VariableBox from "./variableBox";
import VariableCustomization from "./variableCustomization";
import zip_icon from "../../assets/logo/zip-logo.png";
import whatsapp_icon from "../../assets/logo/whatsapp-logo.png";
import logo from "../../assets/logo/logo.png";
import {
  setStoredDraggedVariables,
  setPdfDraggedVariables,
} from "../../redux-slice/variablesSlice";
import { useNavigate } from "react-router-dom";
import {
  convertNodeToBase64,
  createInvitation,
  dataURLtoBuffer,
  dataURLtoFile,
  getBlobName,
  getOnlyUrl,
  longUuid,
  shortUuid,
  uploadFile,
} from "../../utils";
import { generateImageZip } from "../../api/api";
import { pdfjs } from "react-pdf";
import {
  onBackFromVariable,
  setIsPreviewInvitation,
} from "../../redux-slice/filesSlice";
import PreviewPdf from "./previewPdf";
import { saveAs } from "file-saver";
import CaptchaModel from "../captchaModel";
import { setIsShowMessageButtons } from "../../redux-slice/messageSlice";
import { CustomerModel } from "../customer/customerModel";
import { toast } from "react-hot-toast";
import {
  MiniMap,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { debounce } from "../../utils/lodash";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import { getFileStreamFromIndexDBAPI } from "../../indexDB/getFromIndexDB";

import {
  setIsShowImportExportButtons,
  setIsShowSuccessMessage,
  setIsShowWaitingMessage,
} from "../../redux-slice/statusSlice";
import { usePDFContext } from "../../context/Context";
import strokeImage from "../../assets/images/stroke-yellow-hero.png"

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const VariableInsertion = () => {
  const {
    excelData,
    // fileStream,
    pdfData_,
    fileType,
    mimeType,
    isPreviewInvitation,
  } = useSelector((state) => state.files);
  const { pdfData, setPdfData } = usePDFContext();

  const { storedDraggedVariables, pdfDraggedVariables } = useSelector(
    (state) => state.variables
  );

  const { isShowMessageButtons, textMessage, invitationTitle } = useSelector(
    (state) => state.message
  );

  let finalizedDraggedVariables = {};

  if (fileType === "image") {
    finalizedDraggedVariables = { 0: storedDraggedVariables };
  }
  if (fileType === "pdf") {
    finalizedDraggedVariables = pdfDraggedVariables;
  }

  // const imgUrl =
  //   fileStream !== null ? URL.createObjectURL(dataURLtoFile(fileStream)) : null;
  const [numPages, setNumPages] = useState(fileType === "image" ? 1 : 0);
  const [fileStream, setFileStream] = useState("");
  // const [pdfData, setPdfData] = useState("");

  const [imgUrl, setImgUrl] = useState();
  const [pdfBuffer, setPdfBuffer] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [fileSize, setSize] = useState({});
  const [zoomLevel, setZoomLevel] = useState(1);
  const [finalizedVariableImages, setFinalizedVariableImages] = useState({});
  const [initialFileSize, setInitialFileSize] = useState(0);
  const [draggebleImage, setDraggebleImage] = useState(null);
  const [activationKeys, setActivationKeys] = useState({
    panning: [],
    // panning: ["Control"],
    // wheel: [],
    wheel: fileType === "image" ? [] : fileType === "pdf" ? ["Control"] : [],
  });
  const [isTransformWrapperDisabled, setIsTransformWrapperDisabled] =
    useState(false);

  const [variables, setVariables] = useState([]);
  const [alignmentToggle, setAlignmentToggle] = useState([]);
  const [selectedAlignment, setSelectedAlignment] = useState([]);

  const handleDivClick = (id) => {
    console.log("id", id);
    if (fileType === "pdf") {
      const updatedAlignmentToggle = [...selectedAlignment];
      const isIndex = updatedAlignmentToggle.findIndex(
        (item) => item.id === id
      );
      if (isIndex !== -1) {
        updatedAlignmentToggle[isIndex].isAlignment =
          !updatedAlignmentToggle[isIndex].isAlignment;
      } else {
        updatedAlignmentToggle.push({ id: id, isAlignment: true });
      }
      setSelectedAlignment(updatedAlignmentToggle);
    }
  };

  const imageRef = useRef();
  const transformComponentRef = useRef(null);
  const pdfPagesRef = useRef([]);
  const draggedVariableRef = useRef({});
  const draggedVariableImages = useRef({});
  const finalizedVariableImagesRef = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scaleChangeDebounce = useCallback(
    debounce((value) => setZoomLevel(value), 1000),
    []
  );

  const calculateWidth = (percent) => {
    const baseWidth = 250; // Starting width
    const increment = 125; // Increment per 25% decrease
    const adjustmentFactor = Math.floor((100 - percent) / 25); // Determine how many increments to add
    return baseWidth + adjustmentFactor * increment;
  };

  const calculateHeight = (percent) => {
    const baseHeight = 55; // Starting height in pixels
    const increment = 55; // Maximum increment when percent is 25%
    const adjustmentFactor = (100 - percent) / 100; // Proportional factor
    return baseHeight + adjustmentFactor * increment;
  };

  const calculateFontSize = (percent) => {
    const baseFontSize = 22; // Starting font size in pixels
    const increment = 38; // Maximum increment when percent is 25%
    const adjustmentFactor = (100 - percent) / 100; // Proportional factor
    return baseFontSize + adjustmentFactor * increment;
  };
  const calculateIconSize = (percent) => {
    const baseFontSize = 22; // Starting font size in pixels
    const increment = 28; // Maximum increment when percent is 25%
    const adjustmentFactor = (100 - percent) / 100; // Proportional factor
    return baseFontSize + adjustmentFactor * increment;
  };

  const pdfWidth = calculateWidth(zoomLevel * 100);
  const pdfHeight = calculateHeight(zoomLevel * 100);
  const pdfFontSize = calculateFontSize(zoomLevel * 100);
  const pdfIconSize = calculateIconSize(zoomLevel * 100);

  useEffect(() => {
    getFileStreamFromIndexDB();
  }, [fileType, pdfData]);

  const getFileStreamFromIndexDB = async () => {
    try {
      const fileObject =
        fileType === "image"
          ? await getFileStreamFromIndexDBAPI("imageFile")
          : fileType === "pdf"
          ? await getFileStreamFromIndexDBAPI("pdfFile")
          : {};
      if (fileObject?.imageStream) {
        setFileStream(fileObject.imageStream);
        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.imageStream))
            : null;
        setImgUrl(imgUrl);
      }
      if (fileObject?.pdfStream) {
        setPdfData(fileObject.pdfStream);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addVariableImagesToFinalizeRef = async (element, index) => {
    try {
      const nodeId = element?.id;
      if (element && nodeId) {
        if (index === 3) {
          console.timeEnd("modifying");
        }
        const img = new Image();
        const base64 = await domtoimage.toPng(element);
        img.src = base64;
        finalizedVariableImagesRef.current[nodeId] = img;
        setFinalizedVariableImages(finalizedVariableImagesRef.current);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addMultipleVariableRefs = async (el, outerIndex, index) => {
    const nodeId = el?.id;
    const nodeHeight = el?.clientHeight;
    const nodeWidth = el?.clientWidth;
    if (el && nodeId) {
      draggedVariableImages.current = { ...draggedVariableImages.current };
      draggedVariableRef.current[nodeId] = el;
      const img = new Image();
      const base64 = await domtoimage.toPng(el);
      if (String(base64 || "").split(",")[1]) {
        img.src = base64;
        draggedVariableImages.current[nodeId] = {
          base64,
          nodeHeight,
          nodeWidth,
        };
        setDraggebleImage(draggedVariableImages.current);
      }
    }
  };

  const dragStart = async (e, varType, varData, index, fileType) => {
    console.log("::::::dragStart---", varType);
    const nodeId = e.target?.id;
    const divCoordinates = e.target.getBoundingClientRect();
    const newCoordinates = {
      diffX: e.pageX - divCoordinates.x - (e.pageX - e.clientX),
      diffY: e.pageY - divCoordinates.y - (e.pageY - e.clientY),
    };
    const data = {
      type: varType,
      ...newCoordinates,
    };
    const payload = {
      ...varData,
      ...newCoordinates,
      ...(fileType === "pdf" ? { sourceIndex: index } : {}),
    };
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify(payload?.isDivOnImage ? payload : data)
    );

    if (finalizedVariableImagesRef.current[nodeId + "-images"]) {
      e.dataTransfer.setDragImage(
        finalizedVariableImagesRef.current[nodeId + "-images"],
        newCoordinates.diffX,
        newCoordinates.diffY
      );
    }
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const calculatePosition = (div, image) => {
    const diffY = div.pageY - div.clientY;
    const diffX = div.pageX - div.clientX;
    const newTop = div.pageY - image.y - diffY - div.diffY;

    const newLeft = div.pageX - image.x - diffX - div.diffX;
    return { newTop, newLeft };
  };

  const drop = (e) => {
    setIsTransformWrapperDisabled(false);
    dispatch(setPdfDraggedVariables({}));
    setActivationKeys({
      ...activationKeys,
      panning: [],
    });
    if (isPreviewInvitation) {
      return;
    }
    e.preventDefault();
    const clonedDraggedVariables = JSON.parse(
      JSON.stringify(storedDraggedVariables)
    );
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    const newDivPosition = {
      pageY: e.pageY,
      pageX: e.pageX,
      clientY: e.clientY,
      clientX: e.clientX,
      diffX: data.diffX,
      diffY: data.diffY,
    };
    const imagePosition = imageRef.current.getBoundingClientRect();
    const { newTop, newLeft } = calculatePosition(
      newDivPosition,
      imagePosition
    );

    const scaledNewTop = newTop / zoomLevel;
    const scaledNewLeft = newLeft / zoomLevel;

    if (data?.isDivOnImage) {
      const divIndex = storedDraggedVariables.findIndex(
        (info) => info.id === data.id
      );
      data.x = scaledNewLeft;
      data.y = scaledNewTop;
      clonedDraggedVariables.splice(divIndex, 1, data);
      dispatch(setStoredDraggedVariables(clonedDraggedVariables));
      return;
    }

    const variableData = {
      id: shortUuid(),
      x: scaledNewLeft,
      y: scaledNewTop,
      type: data.type,
      fontType: { property: "poppins", style: "poppins", name: "Poppins" },
      color: {
        r: "0",
        g: "0",
        b: "0",
        a: "1",
        string: "rgb(0,0,0)",
      },
      fontSize: 22,
      isDivOnImage: true,
    };
    clonedDraggedVariables.push(variableData);
    dispatch(setStoredDraggedVariables(clonedDraggedVariables));
  };

  const dropOnPdf = (e, destinationIndex) => {
    setIsTransformWrapperDisabled(false);
    dispatch(setStoredDraggedVariables([]));
    if (isPreviewInvitation) {
      return;
    }
    e.preventDefault();
    const clonedPdfDraggedVariables = JSON.parse(
      JSON.stringify(pdfDraggedVariables)
    );
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    const newDivPosition = {
      pageY: e.pageY,
      pageX: e.pageX,
      clientY: e.clientY,
      clientX: e.clientX,
      diffX: data.diffX,
      diffY: data.diffY,
    };
    const imagePosition =
      pdfPagesRef.current[destinationIndex].getBoundingClientRect();
    const { newTop, newLeft } = calculatePosition(
      newDivPosition,
      imagePosition
    );

    const scaledNewTop = newTop / zoomLevel;
    const scaledNewLeft = newLeft / zoomLevel;

    data.x = scaledNewLeft;
    data.y = scaledNewTop;
    data.pageIndex = destinationIndex;

    if (data?.isDivOnImage) {
      if ("sourceIndex" in data) {
        if (data.sourceIndex === destinationIndex) {
          const divIndex = pdfDraggedVariables[destinationIndex].findIndex(
            (info) => info.id === data.id
          );
          clonedPdfDraggedVariables[destinationIndex].splice(divIndex, 1, data);
          dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
          return;
        } else {
          const sourceDivIndex = pdfDraggedVariables[
            data.sourceIndex
          ].findIndex((info) => info.id === data.id);
          clonedPdfDraggedVariables[data.sourceIndex].splice(sourceDivIndex, 1);
          clonedPdfDraggedVariables[destinationIndex].splice(
            clonedPdfDraggedVariables[destinationIndex].length - 1,
            0,
            data
          );
          dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
          return;
        }
      }
    }

    const variableData = {
      id: shortUuid(),
      x: scaledNewLeft,
      y: scaledNewTop,
      type: data.type,
      fontType: { property: "poppins", style: "poppins", name: "Poppins" },
      fontSize: 22,
      color: {
        r: "0",
        g: "0",
        b: "0",
        a: "1",
        string: "rgb(0,0,0)",
      },
      isDivOnImage: true,
    };
    clonedPdfDraggedVariables[destinationIndex].push(variableData);
    dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  };

  const updateFontColor = (color, innerIndex, index) => {
    if (fileType === "image") {
      updateVariableFontColor(color.rgb, innerIndex);
      return;
    }
    if (fileType === "pdf") {
      updatePdfVariableFontColor(color.rgb, innerIndex, index);
      return;
    }
  };

  const updateFontSize = (size, innerIndex, index, updatedTop) => {
    if (fileType === "image") {
      updateVariableFontSize(size, innerIndex, updatedTop, index);
      return;
    }
    if (fileType === "pdf") {
      updatePdfVariableFontSize(size, innerIndex, index, updatedTop);
      return;
    }
  };

  const updateVariableFontSize = (size, index, updatedTop) => {
    const clonedDraggedVariables = JSON.parse(
      JSON.stringify(storedDraggedVariables)
    );
    clonedDraggedVariables[index].fontSize = size;
    clonedDraggedVariables[index].top = updatedTop
      ? updatedTop
      : clonedDraggedVariables[index].top;
    dispatch(setStoredDraggedVariables(clonedDraggedVariables));
  };

  const updatePdfVariableFontSize = (size, innerIndex, index, updatedTop) => {
    const clonedPdfDraggedVariables = JSON.parse(
      JSON.stringify(pdfDraggedVariables)
    );

    clonedPdfDraggedVariables[index][innerIndex].fontSize = size;
    clonedPdfDraggedVariables[index][innerIndex].top = updatedTop
      ? updatedTop
      : clonedPdfDraggedVariables[index][innerIndex].top;
    dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  };

  const updateVariableFontColor = (color, index) => {
    const clonedDraggedVariables = [...storedDraggedVariables];
    const updated = { ...clonedDraggedVariables[index], color };
    clonedDraggedVariables[index] = updated;
    dispatch(setStoredDraggedVariables(clonedDraggedVariables));
  };

  const updatePdfVariableFontColor = (color, innerIndex, index) => {
    const clonedPdfDraggedVariables = JSON.parse(
      JSON.stringify(pdfDraggedVariables)
    );

    clonedPdfDraggedVariables[index][innerIndex].color = color;
    dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  };

  const handleFontFamily = (font, innerIndex, index) => {
    if (fileType === "image") {
      updateVariableFontFamily(font, innerIndex);
      return;
    }
    if (fileType === "pdf") {
      updatePdfVariableFontFamily(font, innerIndex, index);
      return;
    }
  };

  const updateVariableFontFamily = (fontType, index) => {
    const clonedDraggedVariables = JSON.parse(
      JSON.stringify(storedDraggedVariables)
    );
    clonedDraggedVariables[index].fontType = fontType;
    dispatch(setStoredDraggedVariables(clonedDraggedVariables));
  };

  // const updatePdfVariableFontFamily = (fontType, innerIndex, index) => {

  //   const clonedPdfDraggedVariables = JSON.parse(
  //     JSON.stringify(pdfDraggedVariables)
  //   );

  //   // const currentVariable = clonedPdfDraggedVariables[index][innerIndex];

  //   console.log("variables---",variables);
  //   const isBold = variables?.fontWeight === 'bold';

  //   console.log("isBold---",isBold);

  //   // const finalFontType = {
  //   //   ...fontType,
  //   //   property: isBold ? `${fontType.property}Bold` : fontType.property,
  //   //   style: isBold ? `${fontType.style}Bold` : fontType.style,
  //   //   name: isBold ? `${fontType.name} Bold` : fontType.name
  //   // };
  //   // console.log("finalFontType---",finalFontType);

  //   clonedPdfDraggedVariables[index][innerIndex].fontType = fontType;
  //   dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  // };

  const updatePdfVariableFontFamily = (fontType, innerIndex, index) => {
    const clonedPdfDraggedVariables = JSON.parse(
      JSON.stringify(pdfDraggedVariables)
    );

    // Get current font styles
    const currentVariable = clonedPdfDraggedVariables[index][innerIndex];
    const isBold = currentVariable.fontWeight === "bold";
    const isItalic = currentVariable.fontStyle === "italic";

    // Create final font type with style modifications
    const finalFontType = {
      ...fontType,
      property: fontType.property,
      style: `${fontType.style}${isBold ? "Bold" : ""}${
        isItalic ? "Italic" : ""
      }`,
      name: fontType.name,
      type: fontType.type,
    };

    clonedPdfDraggedVariables[index][innerIndex].fontType = finalFontType;
    dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  };

  const removeDraggedVariable = (innerIndex, index) => {
    console.log("innerIndex", innerIndex);
    console.log("index", index);
    if (fileType === "image") {
      const clonedDraggedVariables = JSON.parse(
        JSON.stringify(storedDraggedVariables)
      );
      clonedDraggedVariables.splice(innerIndex, 1);
      dispatch(setStoredDraggedVariables(clonedDraggedVariables));
      return;
    }
    if (fileType === "pdf") {
      const clonedPdfDraggedVariables = JSON.parse(
        JSON.stringify(pdfDraggedVariables)
      );
      clonedPdfDraggedVariables[index].splice(innerIndex, 1);
      dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
      return;
    }
  };

  const handleAlignmentToggle = (innerIndex, index) => {
    if (fileType === "image") {
      setAlignmentToggle(!alignmentToggle);
    }
    if (fileType === "pdf") {
      const updatedAlignmentToggle = [...alignmentToggle];
      const isIndex = updatedAlignmentToggle.findIndex(
        (item) => item.id === innerIndex
      );
      if (isIndex !== -1) {
        updatedAlignmentToggle[isIndex].isAlignment =
          !updatedAlignmentToggle[isIndex].isAlignment;
      } else {
        updatedAlignmentToggle.push({ id: innerIndex, isAlignment: true });
      }
      setAlignmentToggle(updatedAlignmentToggle);
    }
  };

  const handleVariableTextRef = (textRef, innerIndex, index, variableData) => {
    if (textRef && imageRef.current) {
      const textCoordinates = textRef.getBoundingClientRect();
      const imageCoordinates = imageRef.current.getBoundingClientRect();
      const textX = (textCoordinates.left - imageCoordinates.left) / zoomLevel;
      const textY = (textCoordinates.top - imageCoordinates.top) / zoomLevel;
      const clonedDraggedVariables = JSON.parse(
        JSON.stringify(storedDraggedVariables)
      );
      clonedDraggedVariables[innerIndex]["left"] = textX;
      clonedDraggedVariables[innerIndex]["top"] = textY;
      dispatch(setStoredDraggedVariables(clonedDraggedVariables));
      return;
    }
    if (textRef && pdfPagesRef.current[index] && variableData) {
      const clonedPdfDraggedVariables = JSON.parse(
        JSON.stringify(pdfDraggedVariables)
      );
      const textCoordinates = textRef.getBoundingClientRect();
      const imageCoordinates =
        pdfPagesRef.current[index].getBoundingClientRect();
      const textX = (textCoordinates.left - imageCoordinates.left) / zoomLevel;
      const textY = (textCoordinates.top - imageCoordinates.top) / zoomLevel;
      clonedPdfDraggedVariables[index][innerIndex]["left"] = textX;
      clonedPdfDraggedVariables[index][innerIndex]["top"] = textY;
      dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
      return;
    }
  };

  const getPdfBuffer = async () => {
    try {
      const pdfObject = await getFileStreamFromIndexDBAPI("pdfFile");
      // const buffer = await dataURLtoBuffer(pdfObject?.pdfStream);
      const buffer = await dataURLtoBuffer(pdfData);
      setPdfBuffer(buffer);
      loadPdfFromBuffer(buffer);
    } catch (error) {
      console.log(error);
    }
  };

  const createPagesVariablesArray = (pages) => {
    let allPagesVariables = {};
    for (let i = 0; i < pages; i++) {
      allPagesVariables[i] =
        pdfDraggedVariables[i]?.length > 0 ? pdfDraggedVariables[i] : [];
    }
    dispatch(setPdfDraggedVariables(allPagesVariables));
  };

  const loadPdfFromBuffer = (pdfBuffer) => {
    const pdf = pdfjs.getDocument({ data: pdfBuffer });
    pdf.promise.then((pdfDocument) => {
      setNumPages(pdfDocument.numPages);
      createPagesVariablesArray(pdfDocument.numPages);
    });
  };

  const onMouseUpDraggedVariable = (e) => {
    // setActivationKeys({
    //   ...activationKeys,
    //   panning: [],
    // });
    setIsTransformWrapperDisabled(false);
  };
  const onMouseDownDraggedVariable = () => {
    // setActivationKeys({
    //   ...activationKeys,
    //   panning: ["no-key"],
    // });
    setIsTransformWrapperDisabled(true);
  };

  const onWheelHandler = (e) => {
    try {
      const {
        instance: {
          transformState: { positionX, positionY, scale },
        },
        setTransform,
      } = transformComponentRef.current;

      const transformComponentHeight =
        transformComponentRef.current?.instance?.contentComponent?.getBoundingClientRect()
          .height || 0;

      const transFormComponentWrapperHeight =
        transformComponentRef.current.instance.contentComponent.parentElement
          .clientHeight;

      if (transformComponentHeight > transFormComponentWrapperHeight) {
        if (e?.deltaY > 0) {
          if (
            !(
              Math.abs(positionY) >=
              transformComponentHeight - transFormComponentWrapperHeight
            )
          ) {
            setTransform(
              positionX,
              positionY - Math.abs(e.deltaY) * 2,
              scale,
              0
            );
          } else {
            setTransform(
              positionX,
              transFormComponentWrapperHeight - transformComponentHeight,
              scale,
              0
            );
          }
        } else {
          if (positionY >= 0) {
            setTransform(positionX, 0, scale);
          } else {
            setTransform(
              positionX,
              positionY + Math.abs(e.deltaY) * 2,
              scale,
              0
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderPDF = useMemo(() => {
    return (
      fileType === "pdf" &&
      numPages !== 0 &&
      pdfBuffer && (
        <TransformWrapper
          disabled={isTransformWrapperDisabled}
          // disabled={false}
          initialScale={1}
          minScale={0.1}
          defaultPositionX={0}
          defaultPositionY={0}
          zoomIn={{ step: 0.5 }}
          zoomOut={{ step: 0.5 }}
          limitToBounds={true}
          ref={transformComponentRef}
          smooth={false}
          wheel={{ activationKeys: activationKeys.wheel }}
          panning={{ activationKeys: activationKeys.panning }}
        >
          <TransformComponent
            contentClass="render-pdf-wrapper"
            contentStyle={{ position: "relative" }}
            // wrapperStyle={{ overflowY: "auto" }}
          >
            <div className="pdf-wrapper" onWheel={onWheelHandler}>
              <div>
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={`page-${index}`}
                    className="pdf-page position-relative"
                    ref={(el) => {
                      pdfPagesRef.current[index] = el;
                    }}
                    onDrop={(e) => dropOnPdf(e, index)}
                    onDragOver={onDragOver}
                  >
                    <PreviewPdf pdfBuffer={pdfBuffer} pageNo={index + 1} />
                    {/* {index === numPages - 1 && (
                    <div 
                      style={{
                        position: 'absolute',
                        bottom: '0px',
                        right: '10px',
                        fontSize: '12px',
                        color: '#000',
                        fontWeight: '600',
                        zIndex: 999,
                        textAlign: 'right'
                      }}
                    >
                      <span style={{fontSize: '12px' }}>RAPID SENDER</span>
                      <br />
                      Personalized Invitations, Simplified.
                    </div>
                  )} */}

                    {index === numPages - 1 && (
                      <div
                        className="d-flex  align-items-center"
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          left: "10px",
                          zIndex: 999,
                        }}
                      >
                        {/* Left side logo */}
                        <div>
                          <img
                            src={logo}
                            alt="Rapid Sender Logo"
                            style={{
                              height: "32px",
                              width: "auto",
                            }}
                          />
                        </div>

                        {/* <div
                          style={{
                            fontSize: '10px',
                            color: '#000',
                            fontWeight: '600',
                          }}
                        >
                          <span style={{ fontSize: '12px', }}>RAPID SENDER</span>
                          <br />
                          Personalized Invitations, Simplified.
                        </div> */}
                      </div>
                    )}

                    {pdfDraggedVariables[index]?.length > 0 &&
                      pdfDraggedVariables[index].map((variable, i) => {
                        const { r, g, b } = variable.color;
                        const isAlignmentVariable = selectedAlignment?.find(
                          (item) => item.id === i
                        )?.isAlignment;
                        return (
                          <div
                            key={`variable-${index}`}
                            className="position-absolute"
                            style={{
                              top: isPreviewInvitation
                                ? variable.top
                                : variable.y,
                              // left: isPreviewInvitation
                              //   ? variable.left
                              //   : variable.x,

                              left: isAlignmentVariable
                                ? "50%"
                                : isPreviewInvitation
                                ? variable.left
                                : variable.x,
                              transform: isAlignmentVariable
                                ? "translateX(-50%)"
                                : "",
                            }}
                          >
                            {isPreviewInvitation ? (
                              <p
                                className="mb-0"
                                draggable={false}
                                style={{
                                  color: `rgb(${r},${g},${b})`,
                                  fontFamily: variable.fontType.property,
                                  fontSize: `${variable.fontSize}px`,
                                  fontWeight: variable.fontWeight,
                                  fontStyle: variable.fontStyle,
                                }}
                              >
                                {excelData.guestData[0][variable.type]}
                              </p>
                            ) : (
                              <VariableBox
                                isDraggable={true}
                                header={variable.type}
                                dragStart={dragStart}
                                variableData={variable}
                                innerIndex={i}
                                index={index}
                                removeDraggedVariable={removeDraggedVariable}
                                handleAlignmentToggle={handleAlignmentToggle}
                                isDeletable={true}
                                isAlignment={true}
                                handleVariableTextRef={handleVariableTextRef}
                                type="pdf"
                                bgColor="#ffffff87"
                                draggedVariableRef={draggedVariableRef}
                                onMouseUpDraggedVariable={
                                  onMouseUpDraggedVariable
                                }
                                onMouseDownDraggedVariable={
                                  onMouseDownDraggedVariable
                                }
                                alignmentToggle={alignmentToggle}
                                setAlignmentToggle={setAlignmentToggle}
                                handleDivClick={handleDivClick}
                                selectedAlignment={selectedAlignment}
                                setSelectedAlignment={setSelectedAlignment}

                                // pdfWidth={pdfWidth}
                                // pdfHeight={pdfHeight}
                                // pdfFontSize={pdfFontSize}
                                // pdfIconSize={pdfIconSize}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                ))}
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
      )
    );
    // eslint-disable-next-line
  }, [
    pdfDraggedVariables,
    isPreviewInvitation,
    pdfBuffer,
    numPages,
    isTransformWrapperDisabled,
    activationKeys,
    zoomLevel,
    alignmentToggle,
    selectedAlignment,

    // transformComponentHeight,
  ]);

  const downloadImageZip = async () => {
    try {
      const link = await uploadFileToBlob();
      const payload = {
        link,
        guests: excelData.guestData,
        coordinates:
          fileType === "image" ? storedDraggedVariables : pdfDraggedVariables,
      };
      if (excelData?.guestData?.length > 0) {
        setIsLoading(true);
        const zipBuffer = await generateImageZip(
          JSON.stringify(payload),
          fileType
        );
        if (zipBuffer) {
          setIsLoading(false);
          saveAs(zipBuffer, "invitations.zip");
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // const invitationPromise = (contact) => {
  //   new Promise(async (resolve) => {
  //     const coordinates =
  //       fileType === "image" ? storedDraggedVariables : pdfDraggedVariables;
  //     let link = await uploadFileToBlob();
  //     link = getOnlyUrl(link);
  //     // delete excelData?.guestData?.isUpdating;
  //     const invitation_id = await createInvitation(
  //       link,
  //       excelData.guestData,
  //       coordinates,
  //       mimeType,
  //       textMessage,
  //       contact
  //     );
  //     if (invitation_id) {
  //       resolve();
  //       navigate(`/invitation-status/${invitation_id}`);
  //       // toast.success("Invitation Created Successfully!");
  //     } else {
  //       toast.error(`Please create invitation again.`);
  //     }
  //     resolve();
  //   });
  // };

  const invitationPromise = async (contact) => {
    try {
      const coordinates =
        fileType === "image" ? storedDraggedVariables : pdfDraggedVariables;
      let link = await uploadFileToBlob();
      link = getOnlyUrl(link);
      // delete excelData?.guestData?.isUpdating;
      const invitation_id = await createInvitation(
        link,
        excelData.guestData,
        coordinates,
        mimeType,
        textMessage,
        contact,
        invitationTitle
      );
      // if (invitationId?.invitation_id || invitationId?.short_id) {
      if (invitation_id) {
        dispatch(setIsShowWaitingMessage(true));
        dispatch(setIsShowImportExportButtons(false));
        dispatch(setIsShowSuccessMessage(false));
        navigate(`/invitation-status/${invitation_id}`);
        // navigate(
        //   `/invitation-status/${
        //     invitationId?.short_id || invitationId?.invitation_id
        //   }`
        // );
        // toast.success("Invitation Created Successfully!");
      } else {
        toast.error(`Please create invitation again.`);
      }
    } catch (error) {
      console.log("invitationPromise-error", error);
      toast.error(`Please create invitation again.`);
    }
  };

  const storeAllData = async (contact) => {
    try {
      toast.promise(invitationPromise(contact), {
        loading: "Invitation creating",
        success: "Invitation created👌",
        error: "Try again after sometime 🤯",
      });
    } catch (error) {
      console.log("storeAllData-error", error);
    }
  };

  const uploadFileToBlob = async () => {
    const buf = await dataURLtoBuffer(
      fileType === "pdf" ? pdfData : fileStream
    );
    const lUuid = `invitation-${longUuid()}`;
    const blobName = getBlobName(lUuid, mimeType);
    const additionalUploadProps = {
      blobHTTPHeaders: {
        blobContentType: mimeType,
      },
    };

    const link = await uploadFile(
      buf,
      blobName,
      process.env.REACT_APP_INVITATION_CARD_PDF_CONTAINER_NAME,
      additionalUploadProps
    );
    return link;
  };

  const validateGuestData = () => {
    const isShowVariables = excelData.guestData.every((guest) => {
      return;
    });
  };

  useEffect(() => {
    if (pdfData) {
      getPdfBuffer();
    }
    // eslint-disable-next-line
  }, [pdfData]);

  useEffect(() => {
    let resizeObserver = {};
    if (transformComponentRef.current?.instance.contentComponent.firstChild) {
      resizeObserver = new ResizeObserver((entries) => {
        // You can iterate all of the element entries observed
        for (const entry of entries) {
          setInitialFileSize(entry.contentRect.width);
          // Do something on resize, access the element on `entry.target`
        }
      });
      resizeObserver.observe(
        transformComponentRef.current?.instance.contentComponent.firstChild
      );
    }

    // return () => {
    //   resizeObserver?.disconnect();
    //   // transformComponentRef.current?.instance.contentComponent.firstChild.removeEventListener(
    //   //   "width",
    //   //   () => {
    //   //     console.log("widht changed");
    //   //   }
    //   // );
    // };
  }, [transformComponentRef.current?.instance.contentComponent.firstChild]);

  // react-zoom-pan-pinch

  // const VariableInsertion = () => {

  const scaleInterval = setInterval(() => {
    if (
      Number(
        transformComponentRef.current?.instance.transformState.scale
      ).toFixed(2) !== zoomLevel
    ) {
      setZoomLevel(
        Number(
          transformComponentRef.current?.instance.transformState.scale
        ).toFixed(2)
      );
    }
  }, 100);

  useEffect(() => {
    return () => {
      clearInterval(scaleInterval);
    };
  });

  const fitToWidth = async () => {
    try {
      if (fileType === "image") {
        if (imageRef.current) {
          const containerWidth =
            transformComponentRef.current.instance.contentComponent
              .parentElement.offsetWidth;
          const imageWidth = imageRef.current.offsetWidth;
          const newZoomLevel = containerWidth / imageWidth;
          transformComponentRef.current.centerView(newZoomLevel);
          setZoomLevel(newZoomLevel);
        }
      }
      if (fileType === "pdf") {
        const {
          instance: {
            transformState: { positionX, positionY, scale },
          },
          setTransform,
        } = transformComponentRef.current;
        const transFormComponentWrapperWidth =
          transformComponentRef.current.instance.contentComponent.parentElement
            .offsetWidth;
        const transformComponentWidth =
          transformComponentRef.current.instance.contentComponent.getBoundingClientRect()
            .width;

        // transformComponentRef.current.centerView(0.3194444);
        // setTransform(0, 0, 0.31944444);
        setTransform(0, 0, transFormComponentWrapperWidth / initialFileSize);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const zoomInHandler = async () => {
    if (transformComponentRef.current) {
      transformComponentRef.current.zoomIn(0.1, 300, "easeOut");
    }
  };

  const zoomOutHandler = async () => {
    if (transformComponentRef.current) {
      transformComponentRef.current.zoomOut(0.1, 300, "easeOut");
    }
  };

  const setZoomTo100Percent = () => {
    setZoomLevel(1); // Set zoom level to 100%
    transformComponentRef.current.setTransform(0, 0, 1);
  };

  const renderScaleStatus = useMemo(
    () => (
      <div className="scalling-btn-wrapper d-flex position-absolute w-100 ps-3 pe-2">
        <button className="scalling-btn" onClick={setZoomTo100Percent}>
          {`${Number(zoomLevel * 100).toPrecision(4)}%`}
        </button>
        <button className="scalling-btn ms-auto" onClick={zoomInHandler}>
          +
        </button>
        <button className="scalling-btn" onClick={zoomOutHandler}>
          -
        </button>
        <button className="scalling-btn" onClick={fitToWidth}>
          Fit to width
        </button>
      </div>
    ),
    [zoomLevel, initialFileSize]
  );

  const renderMainImage = useMemo(
    () => (
      <img
        src={imgUrl}
        ref={imageRef}
        alt="RAPID SENDER – Digital Invitation Sending Platform"
        draggable={false}
        id="imageElement"
      />
    ),
    [imgUrl]
  );

  const renderImage = useMemo(
    () => (
      <TransformWrapper
        smooth={true}
        minScale={0.1}
        initialScale={1}
        limitToBounds={true}
        defaultPositionX={0}
        defaultPositionY={0}
        zoomIn={{ step: 0.5 }}
        zoomOut={{ step: 0.5 }}
        ref={transformComponentRef}
        disabled={isTransformWrapperDisabled}
        // panning={{ activationKeys: activationKeys.panning }}
        wheel={{ activationKeys: activationKeys.wheel }}
        panning={{ activationKeys: activationKeys.panning }}
      >
        {() => (
          <>
            {/* <div style={{ position: "absolute", zIndex: 5 }}>
              <MiniMap width={150} height={100} />
            </div> */}
            <TransformComponent contentStyle={{ position: "relative" }}>
              {renderMainImage}
              {/* <img
                src={imgUrl}
                ref={imageRef}
                alt="RAPID SENDER – Digital Invitation Sending Platform"
                draggable={false}
                id="imageElement"
              /> */}
              <div
                className="position-absolute"
                style={{
                  height: "100%",
                  width: "100%",
                }}
                onDrop={(e) => {
                  drop(e);
                }}
                onDragOver={onDragOver}
              >
                {storedDraggedVariables.length > 0 &&
                  storedDraggedVariables.map((variable, index) => {
                    const { r, g, b } = variable.color;
                    return (
                      <div
                        key={`variable-${index}`}
                        className="position-absolute"
                        style={{
                          top: isPreviewInvitation ? variable.top : variable.y,
                          left: isPreviewInvitation
                            ? variable.left
                            : variable.x,
                        }}
                      >
                        {isPreviewInvitation ? (
                          <p
                            className="mb-0 text-nowrap"
                            draggable={false}
                            style={{
                              color: `rgb(${r},${g},${b})`,
                              fontFamily: variable.fontType.property,
                              fontSize: `${variable.fontSize}px`,
                            }}
                          >
                            {excelData.guestData[0][variable.type]}
                          </p>
                        ) : (
                          <VariableBox
                            isDraggable={true}
                            header={variable.type}
                            dragStart={dragStart}
                            variableData={variable}
                            innerIndex={index}
                            removeDraggedVariable={removeDraggedVariable}
                            handleAlignmentToggle={handleAlignmentToggle}
                            isDeletable={true}
                            isAlignment={true}
                            handleVariableTextRef={handleVariableTextRef}
                            type="image"
                            bgColor="#ffffff87"
                            onMouseUpDraggedVariable={onMouseUpDraggedVariable}
                            onMouseDownDraggedVariable={
                              onMouseDownDraggedVariable
                            }
                            alignmentToggle={alignmentToggle}
                            setAlignmentToggle={setAlignmentToggle}
                            handleDivClick={handleDivClick}
                            selectedAlignment={selectedAlignment}
                            setSelectedAlignment={setSelectedAlignment}
                            // pdfWidth={pdfWidth}
                            // pdfHeight={pdfHeight}
                            // pdfFontSize={pdfFontSize}
                            // pdfIconSize={pdfIconSize}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    ),
    [
      imgUrl,
      zoomLevel,
      activationKeys,
      isPreviewInvitation,
      storedDraggedVariables,
      isTransformWrapperDisabled,
    ] // TODO: Remove "Spare" keyword
  );

  const renderStoredDraggedVariables = useMemo(
    () =>
      Object.values(finalizedDraggedVariables).map(
        (pageVariables, outerIndex) =>
          pageVariables?.length > 0 &&
          pageVariables.map((variable, index) => {
            return (
              <div
                key={`variable-${index}`}
                className="position-absolute"
                id={`${variable}`}
                style={{
                  zIndex: -10,
                }}
              >
                <VariableBox
                  addMultipleVariableRefs={(el) =>
                    addMultipleVariableRefs(el, outerIndex, index)
                  }
                  isDraggable={false}
                  header={variable.type}
                  dragStart={dragStart}
                  variableData={variable}
                  index={outerIndex}
                  innerIndex={index}
                  removeDraggedVariable={removeDraggedVariable}
                  handleAlignmentToggle={handleAlignmentToggle}
                  isDeletable={true}
                  isAlignment={true}
                  type="image"
                  bgColor="#ffffff87"
                  onMouseUpDraggedVariable={onMouseUpDraggedVariable}
                  onMouseDownDraggedVariable={onMouseDownDraggedVariable}
                  elementForGhost={true}
                  alignmentToggle={alignmentToggle}
                  setAlignmentToggle={setAlignmentToggle}
                  handleDivClick={handleDivClick}
                  selectedAlignment={selectedAlignment}
                  setSelectedAlignment={setSelectedAlignment}
                  // pdfWidth={pdfWidth}
                  // pdfHeight={pdfHeight}
                  // pdfFontSize={pdfFontSize}
                  // pdfIconSize={pdfIconSize}
                />
              </div>
            );
          })
      ),
    [storedDraggedVariables, isPreviewInvitation, zoomLevel]
  );

  const renderDraggedVariableImages = useMemo(
    () =>
      Object.entries(draggedVariableImages.current)?.length > 0 &&
      Object.entries(draggedVariableImages.current)?.map((base64, index) => {
        return base64[1]["base64"] ? (
          <img
            id={`${base64[0]}-images`}
            key={`createdVariableImage.${index}`}
            src={base64[1]["base64"]}
            style={{
              zIndex: -10,
              // position: "absolute",
              transform: "translate(1px, 1px)",
              height: base64[1]["nodeHeight"] * zoomLevel,
              width: base64[1]["nodeWidth"] * zoomLevel,
            }}
            alt="RAPID SENDER – Digital Invitation Sending Platform"
            ref={(el) => addVariableImagesToFinalizeRef(el, index)}
          />
        ) : (
          ""
        );
      }),
    [draggebleImage, zoomLevel]
  );

  const renderOriginalariableList = useMemo(
    () =>
      excelData?.excelHeaders?.length > 0 &&
      excelData.excelHeaders.map((header, index) => {
        return (
          header !== "_id" &&
          header !== "status" && (
            <div
              className={`${index !== 0 ? "pt-10" : ""} `}
              key={`variable.${index}`}
            >
              <VariableBox
                isDraggable={true}
                header={header}
                dragStart={dragStart}
                isDeletable={false}
                isAlignment={false}

                // pdfWidth={pdfWidth}
                // pdfHeight={pdfHeight}
                // pdfFontSize={pdfFontSize}
                // pdfIconSize={pdfIconSize}
              />
            </div>
          )
        );
      }),
    [excelData.excelHeaders]
  );

  // Update font styles for image files
  const updateVariableFontStyle = (styles, index) => {
    const clonedDraggedVariables = JSON.parse(
      JSON.stringify(storedDraggedVariables)
    );
    clonedDraggedVariables[index] = {
      ...clonedDraggedVariables[index],
      fontWeight: styles.fontWeight,
      fontStyle: styles.fontStyle,
    };
    dispatch(setStoredDraggedVariables(clonedDraggedVariables));
  };

  // Update font styles for PDF files
  // const updatePdfVariableFontStyle = (styles, innerIndex, index) => {
  //   const clonedPdfDraggedVariables = JSON.parse(
  //     JSON.stringify(pdfDraggedVariables)
  //   );
  //   clonedPdfDraggedVariables[index][innerIndex] = {
  //     ...clonedPdfDraggedVariables[index][innerIndex],
  //     fontWeight: styles.fontWeight,
  //     fontStyle: styles.fontStyle
  //   };

  //   dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  //   setVariables(styles);
  // };

  const updatePdfVariableFontStyle = (styles, innerIndex, index) => {
    const clonedPdfDraggedVariables = JSON.parse(
      JSON.stringify(pdfDraggedVariables)
    );

    const currentVariable = clonedPdfDraggedVariables[index][innerIndex];
    const currentFontType = currentVariable.fontType;

    // Update font weight and style
    const isBold = styles.fontWeight === "bold";
    const isItalic = styles.fontStyle === "italic";

    // Update the font type to include style indicators
    const updatedFontType = {
      ...currentFontType,
      // property: `${currentFontType.property.replace(/Bold|Italic/g, '')}${isBold ? 'Bold' : ''}${isItalic ? 'Italic' : ''}`,
      style: `${currentFontType.style.replace(/Bold|Italic/g, "")}${
        isBold ? "Bold" : ""
      }${isItalic ? "Italic" : ""}`,
      // name: `${currentFontType.name.replace(/ Bold| Italic/g, '')}${isBold ? ' Bold' : ''}${isItalic ? ' Italic' : ''}`
      type: currentFontType.type,
    };

    clonedPdfDraggedVariables[index][innerIndex] = {
      ...currentVariable,
      fontWeight: styles.fontWeight,
      fontStyle: styles.fontStyle,
      fontType: updatedFontType,
    };

    dispatch(setPdfDraggedVariables(clonedPdfDraggedVariables));
  };

  // Handler function that routes to the appropriate update function
  const handleFontStyle = (styles, innerIndex, index) => {
    if (fileType === "image") {
      updateVariableFontStyle(styles, innerIndex);
      return;
    }
    if (fileType === "pdf") {
      updatePdfVariableFontStyle(styles, innerIndex, index);
      return;
    }
  };

  return (
    <>
      <CustomerModel
        show={customerModalShow}
        onHide={() => setCustomerModalShow(false)}
        storeAllData={storeAllData}
      />
      <div className="variable-customization flex-grow-1 d-flex align-items-center justify-content-center p-10"  style={{backgroundImage: `url(${strokeImage})`,backgroundPosition: "center"}}>
        <Container className="h-100 d-flex flex-column justify-content-center align-items-center">
          {/* <div className="above-buttons-wrapper d-flex gap-3">
            <Button
              variant="light"
              className="back-button fs-25 mb-3"
              onClick={() => {
                isPreviewInvitation
                  ? isShowMessageButtons
                    ? dispatch(setIsPreviewInvitation(false))
                    : dispatch(setIsShowMessageButtons(true))
                  : navigate(-1);
                dispatch(onBackFromVariable(true));
              }}
            >
              {left_arrow()} Back
            </Button>
            {!isPreviewInvitation && (
              <Button
                variant="primary"
                className="d-flex preview-button align-items-center justify-content-between px-19 ms-3 header-preview-button"
                onClick={() => dispatch(setIsPreviewInvitation(true))}
              >
                <span className="fs-18">Preview</span>
                {play_icon()}
              </Button>
            )}
          </div> */}
          <div className="variable-insertion-wrapper d-flex justify-content-between">
            <div className="left-section custom-scrollbar">
              <div
                style={{
                  position: "relative",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  display: "flex",
                }}
              >
                {renderScaleStatus}
                {fileType === "image" ? renderImage : ""}
                {fileType === "pdf" ? renderPDF : ""}
              </div>
            </div>
            <div className="right-section w-50 h-100 d-flex flex-column justify-content-between align-items-end">
              {/* <div className="position-relative h-0 w-0 overflow-hidden">
                {renderStoredDraggedVariables}
                {renderDraggedVariableImages}
              </div> */}

              <div>
                <div>
                  <div className="above-buttons-wrapper d-flex gap-3">
                    <Button
                      variant="light"
                      className="back-button fs-25 mb-3 d-flex align-items-center justify-content-center"
                      onClick={() => {
                        isPreviewInvitation
                          ? isShowMessageButtons
                            ? dispatch(setIsPreviewInvitation(false))
                            : dispatch(setIsShowMessageButtons(true))
                          : navigate(-1);
                        dispatch(onBackFromVariable(true));
                      }}
                    >
                      {left_arrow()} Back
                    </Button>
                    {!isPreviewInvitation && (
                      <Button
                        variant="primary"
                        className="preview-button fs-25 mb-3 d-flex align-items-center justify-content-center"
                        // className="d-flex preview-button align-items-center justify-content-between px-19 ms-3 header-preview-button"
                        onClick={() => dispatch(setIsPreviewInvitation(true))}
                      >
                        <span className="fs-18">Preview</span>
                        {play_icon()}
                      </Button>
                    )}
                  </div>
                </div>
                {isPreviewInvitation ? (
                  <>
                    {fileType === "image" && (
                      <div className="variable-customization-wrapper custom-scrollbar">
                        {storedDraggedVariables !== null &&
                          storedDraggedVariables.map((variable, i) => {
                            return (
                              <VariableCustomization
                                variable={variable}
                                innerIndex={i}
                                updateFontColor={updateFontColor}
                                handleFontFamily={handleFontFamily}
                                handleFontStyle={handleFontStyle}
                                updateFontSize={updateFontSize}
                                index={i}
                              />
                            );
                          })}
                      </div>
                    )}
                    {fileType === "pdf" && (
                      <div className="variable-customization-wrapper custom-scrollbar">
                        {Array.from(new Array(numPages), (el, index) => {
                          return (
                            <div key={`page-${index}`}>
                              {pdfDraggedVariables[index].map((variable, i) => {
                                return (
                                  <VariableCustomization
                                    variable={variable}
                                    innerIndex={i}
                                    updateFontColor={updateFontColor}
                                    handleFontFamily={handleFontFamily}
                                    index={index}
                                    updateFontSize={updateFontSize}
                                    handleFontStyle={handleFontStyle}
                                  />
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="variable-boxes-wrapper custom-scrollbar">
                    {renderOriginalariableList}
                  </div>
                )}
              </div>
              {!isPreviewInvitation ? (
                // <Button
                //   variant="primary"
                //   className="d-flex preview-button align-items-center justify-content-between px-19"
                //   onClick={() => dispatch(setIsPreviewInvitation(true))}
                // >
                //   <span className="fs-18">Preview </span> {play_icon()}
                // </Button>
                <></>
              ) : isShowMessageButtons ? (
                <div className="my-3 d-flex flex-column gap-2 custom-message-buttons">
                  <Button
                    variant="primary"
                    className="fs-20 whatsapp-button d-flex justify-content-around align-items-center"
                    onClick={() => {
                      navigate("/invitation-message");
                    }}
                  >
                    {plus_icon("24px", "24px")}
                    <span className="ml-7 ">Add Custom Message</span>
                  </Button>
                  <p
                    // variant="light"
                    className="mb-0 fs-20 pt-20 text-end pr-17 cursor-pointer"
                    onClick={() => {
                      dispatch(setIsShowMessageButtons(false));
                    }}
                  >
                    Skip Message
                  </p>
                </div>
              ) : (
                <div className="mt-3 zip-and-whatapp-button">
                  {/* downloadZip button :start */}

                  <Button
                    variant="warning"
                    className="fs-25 zip-button text-white d-flex justify-content-center align-items-center"
                    onClick={() => {
                      setModalShow(true);
                      setSecretKey("");
                    }}
                  >
                    {isLoading ? (
                      <span className="">Downloading...</span>
                    ) : (
                      <>
                        <span className="ml-7">Download as ZIP</span>
                        <div className="zip-icon-wrapper">
                          <img src={zip_icon} className="w-100 h-auto" alt="RAPID SENDER – Digital Invitation Sending Platform" />
                        </div>
                      </>
                    )}
                  </Button>

                  {/* downloadZip button :end */}

                  {/* Send Invite button :start */}

                  <Button
                    variant="primary"
                    className="fs-25 whatsapp-button d-flex align-items-center"
                    // onClick={storeAllData}
                    onClick={() => {
                      setCustomerModalShow(true);
                    }}
                  >
                    <span className="ml-7 whatsapp-button-text">
                      Send Invite using
                    </span>
                    <img
                      src={whatsapp_icon}
                      className="whatsapp-icon "
                      alt="RAPID SENDER – Digital Invitation Sending Platform"
                    />
                  </Button>

                  {/* Send Invite button :end */}
                </div>
              )}
            </div>
          </div>
          <CaptchaModel
            show={modalShow}
            onHide={() => setModalShow(false)}
            secretKey={secretKey}
            setSecretKey={setSecretKey}
            downloadZip={downloadImageZip}
          />
        </Container>
      </div>
    </>
  );
};

export default VariableInsertion;
