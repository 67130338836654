import React from "react";

const FeaturesSection = () => {
  return (
    <section id="features" className="py-5">
      <div className="container py-5">
        <div className="text-center mb-5">
          <span className="badge bg-primary mb-3 px-3 py-2 rounded-pill">
            <i className="fas fa-star me-2"></i>Key Features
          </span>
          <h2 className="display-5 fw-bold mb-3">Smart WhatsApp Invitation System</h2>
          <p className="lead text-muted mx-auto" style={{ maxWidth: "600px" }}>
            Everything you need to automate your WhatsApp invitations and manage responses effectively.
          </p>
        </div>
        <div className="row g-4">
          <div className="col-md-4">
            <div className="feature-card p-4">
              <div className="feature-icon floating">
                <i className="fas fa-bolt"></i>
              </div>
              <h3 className="h4 mb-3 text-center">Bulk Sending</h3>
              <p className="text-muted text-center">
                Send hundreds of personalized WhatsApp invitations in minutes.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-card p-4">
              <div className="feature-icon floating">
                <i className="fas fa-file-alt"></i>
              </div>
              <h3 className="h4 mb-3 text-center">Personalizing Attachments</h3>
              <p className="text-muted text-center">
                Customize invitations with dynamic variables for a personal touch.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="feature-card p-4">
              <div className="feature-icon floating">
                <i className="fas fa-chart-bar"></i>
              </div>
              <h3 className="h4 mb-3 text-center">Analytics</h3>
              <p className="text-muted text-center">
                Track delivery, responses, and engagement in real-time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
