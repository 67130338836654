import React from "react";
import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";

const PrivacyPage = () => {
  return (
    <div style={{ paddingTop: "76px"}}>
      <Navbar />
        <div class="container mt-5" style={{ paddingBottom: "76px"}}>
          <h1 className="h1">Privacy Policy</h1>
          <p>Last Updated: 31st Jan 2024.</p>
          
          <h2 className="h3">1. Introduction</h2>
          <p>Welcome to Rapid Sender. Your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our web application.</p>
          
          <h2 className="h3">2. Information We Collect</h2>
          <h3 className="h5">2.1 Personal Data</h3>
          <p>When you register or use our platform, we may collect personal details such as your name, email address, phone number, and payment details.</p>
          
          <h3 className="h5">2.2 Usage Data</h3>
          <p>We collect information about how you interact with our platform, such as IP addresses, browser types, and device information.</p>
          
          <h2 className="h3">3. How We Use Your Information</h2>
          <ul>
              <li>To provide and maintain our services.</li>
              <li>To improve user experience and develop new features.</li>
              <li>To communicate with you regarding support, updates, and promotions.</li>
              <li>To comply with legal obligations.</li>
          </ul>
          
          <h2 className="h3">4. Data Sharing & Security</h2>
          <p>We do not sell or share your personal data with third parties except in cases where it is necessary to provide our services or comply with the law.</p>
          <p>We implement appropriate security measures to protect your data from unauthorized access and misuse.</p>
          
          <h2 className="h3">5. Cookies & Tracking Technologies</h2>
          <p>We use cookies and similar tracking technologies to enhance your experience. You can manage your cookie preferences in your browser settings.</p>
          
          <h2 className="h3">6. Your Rights</h2>
          <p>You have the right to access, modify, or delete your personal data. To make any requests, contact us at <a href="mailto:rapid.sender.56@gmail.com
">rapid.sender.56@gmail.com
</a>.</p>
          
          <h2 className="h3">7. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated date.</p>
          
          <h2 className="h3">8. Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <ul>
              <li>Email: <a href="mailto:rapid.sender.56@gmail.com
">rapid.sender.56@gmail.com
</a></li>
              <li>Phone: +91 96248 28267</li>
              <li>Address: Surat, Gujarat, India</li>
          </ul>
        </div>
      {/* <CallToAction /> */}
      <Footer />
    </div>
  );
};

export default PrivacyPage;
