import React, { useEffect } from "react";
import NavbarComponent from "../components/navbar2";
import VariableInsertion from "../components/variableInsertion";

const Variable = () => {
  const preventZoom = (event) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", preventZoom, { passive: false });

    return () => {
      window.removeEventListener("wheel", preventZoom);
    };
  }, []);
  return (
    <div className="bg-yellow h-100vh d-flex flex-column">
      <NavbarComponent />
      <VariableInsertion />
    </div>
  );
};

export default Variable;
