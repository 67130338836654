import { BlobServiceClient } from "@azure/storage-blob";

const blobServiceClient = new BlobServiceClient(
  `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net?${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_SASS_TOKEN}`
);

const getBlobName = (uuid, mimetype) => {
  const imageType = mimetype.split("/")[1];
  return `${uuid}.${imageType}`;
};

const uploadFile = async (file, name, containerName, additionalUploadProps) => {
  try {
    const blobName = name;
    // const containerName = process.env.INVITATION_CARD_THUMBNAIL_CONTAINER_NAME;
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    await blockBlobClient.uploadData(file, additionalUploadProps);
    return blockBlobClient.url;
  } catch (error) {
    console.log("error", error);
  }
};

const deleteBlob = async (blobName, containerName) => {
  try {
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const res = await containerClient.deleteBlob(blobName);
    return res;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

export { getBlobName, uploadFile, deleteBlob };
