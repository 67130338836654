import React from "react";

const PricingSection = () => {
  return (
    <section id="pricing" className="py-5">
      <div className="container py-5">
        <div className="text-center mb-5">
          <span className="badge bg-primary mb-3 px-3 py-2 rounded-pill">
            <i className="fas fa-tag me-2"></i>Pricing Plans
          </span>
          <h2 className="display-5 fw-bold mb-3">Simple & Transparent Pricing</h2>
          <p className="lead text-muted mx-auto" style={{ maxWidth: "600px" }}>
            Choose the perfect plan for your invitation needs.
          </p>
        </div>
        <div className="row g-4">
          {/* Basic Plan */}
          <div className="col-md-4">
            <div className="price-card h-100 shadow-sm p-4">
              <div className="text-center mb-4">
                <div className="feature-icon mb-3">
                  <i className="fas fa-paper-plane"></i>
                </div>
                <h3>Free</h3>
                <div className="mt-3">
                  <span className="display-4 fw-bold">₹0</span>
                  <span className="text-muted">/Guest</span>
                </div>
              </div>
              <ul className="list-unstyled mb-4">
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  25 free credits for dynamic invites
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Create personalized invitation
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Add Customizable Fields
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Email / WhatsApp support
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  For trial use only
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Detailed analytics
                </li>
              </ul>
              <a href="#signup" className="btn btn-outline-warning w-100 btn-lg">
                Get Started
              </a>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="col-md-4">
            <div className="price-card h-100 shadow p-4 border border-warning">
              <div className="popular-badge">Most Popular</div>
              <div className="text-center mb-4">
                <div className="feature-icon mb-3">
                  <i className="fas fa-crown"></i>
                </div>
                <h3>Pro</h3>
                <div className="mt-3">
                  <span className="display-4 fw-bold">₹15</span>
                  <span className="text-muted">/Guest</span>
                </div>
              </div>
              <ul className="list-unstyled mb-4">
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Unlimited dynamic invites
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  For B2B / Business use
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Advanced features
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Detailed analytics
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Priority support
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Dedicated manager
                </li>
              </ul>
              <a href="#signup" className="btn btn-gradient w-100 btn-lg">
                Get Started
              </a>
            </div>
          </div>

          {/* Enterprise Plan */}
          <div className="col-md-4">
            <div className="price-card h-100 shadow-sm p-4">
              <div className="text-center mb-4">
                <div className="feature-icon mb-3">
                  <i className="fas fa-building"></i>
                </div>
                <h3>Enterprise</h3>
                <div className="mt-3">
                  <span className="display-4 fw-bold">Custom</span>
                </div>
              </div>
              <ul className="list-unstyled mb-4">
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Everything in business plan +
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Custom integration
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  Advanced reporting
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  24/7 support
                </li>
                <li className="mb-3">
                  <i className="fas fa-check-circle text-warning me-2"></i>
                  API Access
                </li>
              </ul>
              <a href="/contact" className="btn btn-outline-warning w-100 btn-lg">
                Contact Sales
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
