import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import filesSlice from "../redux-slice/filesSlice";
import variablesSlice from "../redux-slice/variablesSlice";
import statusSlice from "../redux-slice/statusSlice";
import messageSlice from "../redux-slice/messageSlice";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  files: filesSlice,
  variables: variablesSlice,
  status: statusSlice,
  message: messageSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools:
    process.env.REACT_APP_ENVIRONMENT === "development" ||
    process.env.REACT_APP_ENVIRONMENT === "dev",
});

export const persistor = persistStore(store);
