import React from "react";
import NavbarComponent from "../components/navbar2";
import Message from "../components/message";

const InvitationMessage = () => {
  return (
    <section className="bg-yellow h-100vh d-flex flex-column">
      <NavbarComponent />
      <Message />
    </section>
  );
};

export default InvitationMessage;
