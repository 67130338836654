import React, { useRef, useState } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "./index.scss";

export const PhoneInput = ({ handlePhone, handlePhoneStatus }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const handlePhoneNumberChange = (status, value, countryData, number) => {
    handlePhoneStatus(status);
    !showValidation && setShowValidation(true);
    !status && isValid && setIsValid(false);
    if (status) {
      setIsValid(true);
      setPhoneNumber(number.replaceAll(" ", ""));
      handlePhone(number);
    }
  };

  return (
    <div>
      <IntlTelInput
        containerClassName="intl-tel-input"
        inputClassName={`custom-form-control ${
          showValidation ? (isValid ? "isValid" : "inValid") : ""
        } `}
        defaultValue={phoneNumber}
        initialCountry="auto"
        preferredCountries={["in", "us", "at"]}
        onPhoneNumberChange={handlePhoneNumberChange}
        inputProps={{
          // required: true,
          pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
        }}
      />
    </div>
  );
};
