import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SendInvitation from "../pages/SendInvitation";
import Variable from "../pages/Variable";
import InvitationStatus from "../pages/InvitationStatus";
import InvitationMessage from "../pages/InvitationMessage";
import Payment from "../pages/Payment";
import { Toaster } from "react-hot-toast";
import TermsPage from "../pages/TermsPage";
import PrivacyPage from "../pages/PrivacyPage";
import RefundPage from "../pages/RefundPage";
import ContactUsPage from "../pages/ContactUsPage";
import OCRPage from "../pages/OCRPage";

const CustomRoutes = () => {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" exact={true} element={<HomePage />} />
          <Route path="/privacy" exact={true} element={<PrivacyPage />} />
          <Route path="/refund" exact={true} element={<RefundPage />} />
          <Route path="/cancellation" exact={true} element={<RefundPage />} />
          <Route path="/terms" exact={true} element={<TermsPage />} />
          <Route path="/contact" exact={true} element={<ContactUsPage />} />
          <Route path="/signin" exact={true} element={<SignIn />} />
          <Route path="/signup" exact={true} element={<SignUp />} />
          <Route path="/image-to-text-gujarati" exact={true} element={<OCRPage />} />
          <Route path="/ocr" exact={true} element={<OCRPage />} />
          <Route
            path="/send-invitation"
            exact={true}
            element={<SendInvitation />}
          />
          <Route path="/variable" exact={true} element={<Variable />} />
          <Route
            path="/invitation-status/:invitationId"
            exact={true}
            element={<InvitationStatus />}
          />
          <Route
            path="/invitation-status"
            exact={true}
            element={<InvitationStatus />}
          />
          <Route
            path="/invitation-message"
            exact={true}
            element={<InvitationMessage />}
          />
          <Route path="/payment" exact={true} element={<Payment />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default CustomRoutes;
