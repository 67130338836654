// import React from "react";
// import NavbarComponent from ".../components/navbar2";
// import HeroSection from ".../components/heroSection";
// import Tutorial from ".../components/tutorialSection";
// import Testimonial from ".../components/testimonialSection";
// import CallToAction from ".../components/callToAction";
// import Footer from ".../components/footer";
// import OccasionSection from ".../components/occasion";
// import OffersSection from ".../components/offers";
// import TestimonialSection from ".../components/testimonials";
// import InvitationBioSection from ".../components/invitationBio";

import React from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import FeaturesSection from "../components/FeaturesSection";
import HowItWorksSection from "../components/HowItWorksSection";
import PricingSection from "../components/PricingSection";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";

const App = () => {
  return (
    <div>
      <Navbar />
      <HeroSection />
      <FeaturesSection />
      <HowItWorksSection />
      <PricingSection />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default App;

// const HomePage = () => {
//   return (
//     <div className="">
//       <NavbarComponent />
//       <HeroSection />
//       <OccasionSection />
//       <OffersSection />
//       <TestimonialSection />
//       <InvitationBioSection />
//       {/* <Tutorial /> */}
//       {/* <Testimonial /> */}
//       {/* <CallToAction /> */}
//       {/* <Footer /> */}
//     </div>
//   );
// };

// export default HomePage;
