import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invitationWithUsers: {},
  allInvitations: [],
  isShowImportExportButtons: false,
  isShowSuccessMessage: false,
  isShowWaitingMessage: true,
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setInvitationWithUsers: (state, action) => {
      state.invitationWithUsers = action.payload;
    },
    setAllInvitations: (state, action) => {
      state.allInvitations = action.payload;
    },
    setIsShowImportExportButtons: (state, action) => {
      state.isShowImportExportButtons = action.payload;
    },
    setIsShowSuccessMessage: (state, action) => {
      state.isShowSuccessMessage = action.payload;
    },
    setIsShowWaitingMessage: (state, action) => {
      state.isShowWaitingMessage = action.payload;
    },
  },
});
export const {
  setInvitationWithUsers,
  setAllInvitations,
  setIsShowImportExportButtons,
  setIsShowSuccessMessage,
  setIsShowWaitingMessage,
} = statusSlice.actions;

export default statusSlice.reducer;
