import React, { useEffect, useRef } from "react";
import "./index.scss";
import { Badge, Button, Container, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MessageWriter from "./messageWriter";
import Preview from "./preview";
import { left_arrow, right_arrow } from "../../assets/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import zip_icon from "../../assets/logo/zip-logo.png";
import whatsapp_icon from "../../assets/logo/whatsapp-logo.png";
import {
  createInvitation,
  dataURLtoBuffer,
  dataURLtoFile,
  getBlobName,
  getOnlyUrl,
  longUuid,
  uploadFile,
} from "../../utils";
import { generateImageZip, updateInvitationMessage } from "../../api/api";
import { saveAs } from "file-saver";
import { useState } from "react";
import CaptchaModel from "../captchaModel";
import { CustomerModel } from "../customer/customerModel";
import { toast } from "react-hot-toast";
import { getFileStreamFromIndexDBAPI } from "../../indexDB/getFromIndexDB";
import {
  setIsShowImportExportButtons,
  setIsShowSuccessMessage,
  setIsShowWaitingMessage,
} from "../../redux-slice/statusSlice";
import {
  setInvitationTitle,
  setTextMessage,
} from "../../redux-slice/messageSlice";
import { usePDFContext } from "../../context/Context";

const Message = () => {
  const invitationTitleRef = useRef(null);
  const messageWriterRef = useRef(null);
  const params = useParams();
  const location = useLocation();
  const { isUpdateMessage, invitationId } = location.state || {};

  const { excelData, fileType, mimeType } = useSelector((state) => state.files);
  const { storedDraggedVariables, pdfDraggedVariables } = useSelector(
    (state) => state.variables
  );
  const { textMessage, invitationTitle } = useSelector(
    (state) => state.message
  );

  const { pdfData, setPdfData } = usePDFContext();

  const [modalShow, setModalShow] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [customerModalShow, setCustomerModalShow] = useState(false);
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [fileStream, setFileStream] = useState("");
  // const [pdfData, setPdfData] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [variableModalShow, setVariableModalShow] = useState(false);
  const [activeElement, setActiveElement] = useState("");

  const handleActiveElement = (element) => {
    setActiveElement(element);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const addToTextArea = function (text_to_add) {
    if (activeElement === "invitationTitleRef") {
      let textarea = document.getElementById("invitation-title-input");
      let start_position = textarea.selectionStart;
      let end_position = textarea.selectionEnd;

      dispatch(
        setInvitationTitle(
          `${textarea.value.substring(
            0,
            start_position
          )}${text_to_add}${textarea.value.substring(
            end_position,
            textarea.value.length
          )}`
        )
      );
    } else if (activeElement === "messageWriterRef") {
      let textarea = document.getElementById("message-writer");
      let start_position = textarea.selectionStart;
      let end_position = textarea.selectionEnd;

      dispatch(
        setTextMessage(
          `${textarea.value.substring(
            0,
            start_position
          )}${text_to_add}${textarea.value.substring(
            end_position,
            textarea.value.length
          )}`
        )
      );
    }
  };

  const handleExcelHeaders = () => {
    const clonedExcelHeaders = JSON.parse(
      JSON.stringify(excelData.excelHeaders)
    );
    const filteredExcelHeaders =
      clonedExcelHeaders?.length > 0
        ? clonedExcelHeaders?.filter((header) => {
            if (
              header === "_id" ||
              header === "status" ||
              header === "isUpdating"
            ) {
              return false;
            } else {
              return true;
            }
          })
        : [];
    filteredExcelHeaders?.length > 0 && setExcelHeaders(filteredExcelHeaders);
  };

  // const invitationPromise = (contact) => {
  //   console.log("excelData?.guestData", excelData?.guestData);
  //   new Promise(async (resolve) => {
  //     const coordinates =
  //       fileType === "image" ? storedDraggedVariables : pdfDraggedVariables;
  //     let link = await uploadFileToBlob();
  //     link = getOnlyUrl(link);
  //     // delete excelData?.guestData?.isUpdating;
  //     const invitation_id = await createInvitation(
  //       link,
  //       excelData.guestData,
  //       coordinates,
  //       mimeType,
  //       textMessage,
  //       contact
  //     );
  //     if (invitation_id) {
  //       navigate(`/invitation-status/${invitation_id}`);
  //       // toast.success("Invitation Created Successfully!");
  //     } else {
  //       toast.error(`Please create invitation again.`);
  //     }
  //     resolve();
  //   });
  // };

  const invitationPromise = async (contact) => {
    try {
      const coordinates =
        fileType === "image" ? storedDraggedVariables : pdfDraggedVariables;
      let link = await uploadFileToBlob();
      link = getOnlyUrl(link);
      // delete excelData?.guestData?.isUpdating;
      const invitation_id = await createInvitation(
        link,
        excelData.guestData,
        coordinates,
        mimeType,
        textMessage,
        contact,
        invitationTitle
      );

      if (invitation_id) {
        dispatch(setIsShowWaitingMessage(true));
        dispatch(setIsShowImportExportButtons(false));
        dispatch(setIsShowSuccessMessage(false));
        // navigate(
        //   `/invitation-status/${
        //     invitationId?.short_id || invitationId?.invitation_id
        //   }`
        // );
        navigate(`/invitation-status/${invitation_id}`);
        // toast.success("Invitation Created Successfully!");
      } else {
        toast.error(`Please create invitation again.`);
      }
    } catch (error) {
      console.log("invitationPromise-error", error);
      toast.error(`Please create invitation again.`);
    }
  };

  const storeAllData = async (contact) => {
    try {
      toast.promise(invitationPromise(contact), {
        loading: "Invitation creating",
        success: "Invitation created👌",
        error: "Try again after sometime 🤯",
      });
    } catch (error) {
      console.log("storeAllData-error", error);
    }
  };

  const uploadFileToBlob = async () => {
    const buf = await dataURLtoBuffer(
      fileType === "pdf" ? pdfData : fileStream
    );
    const lUuid = `invitation-${longUuid()}`;
    const blobName = getBlobName(lUuid, mimeType);
    const additionalUploadProps = {
      blobHTTPHeaders: {
        blobContentType: mimeType,
      },
    };

    const link = await uploadFile(
      buf,
      blobName,
      process.env.REACT_APP_INVITATION_CARD_PDF_CONTAINER_NAME,
      additionalUploadProps
    );
    return link;
  };

  const downloadZip = async () => {
    try {
      const link = await uploadFileToBlob();

      const payload = {
        link,
        guests: excelData.guestData,
        coordinates:
          fileType === "image" ? storedDraggedVariables : pdfDraggedVariables,
      };
      if (excelData?.guestData?.length > 0) {
        const zipBuffer = await generateImageZip(
          JSON.stringify(payload),
          fileType
        );
        if (zipBuffer) {
          saveAs(zipBuffer, "invitations.zip");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (!fileStream && !pdfData && !excelData) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    excelData?.excelHeaders?.length > 0 && handleExcelHeaders();
  }, [excelData]);

  useEffect(() => {
    getFileStreamFromIndexDB(fileType);
  }, [fileType, pdfData]);

  const getFileStreamFromIndexDB = async (fileType) => {
    try {
      if (fileType === "image") fileType = "imageFile";
      if (fileType === "pdf") fileType = "pdfFile";
      const fileObject = await getFileStreamFromIndexDBAPI(fileType);
      if (fileObject?.imageStream) {
        setFileStream(fileObject.imageStream);
        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.imageStream))
            : null;
        setImgUrl(imgUrl);
      }
      if (fileObject?.pdfStream) {
        setPdfData(fileObject.pdfStream);

        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.pdfStream))
            : null;
        setImgUrl(imgUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateWhatsappMessage = async () => {
    const payload = {
      invitation_id: invitationId,
      field: {
        invitationTitle: invitationTitle,
        message: textMessage,
      },
    };

    try {
      const response = await updateInvitationMessage(payload);

      if (response) {
        toast.success("Message updated successfully");
        navigate(
          `/invitation-status/${response?.invitationId || invitationId}`
        );
      } else {
        throw new Error(response?.message || "Failed to update the message.");
      }
    } catch (error) {
      console.error("Error updating WhatsApp message:", error);
      toast.error(error.message || "An unexpected error occurred.");
    }
  };

  return (
    <>
      <CustomerModel
        show={customerModalShow}
        onHide={() => setCustomerModalShow(false)}
        storeAllData={storeAllData}
      />
      <Container className="m-auto invitation-message-wrapper ">
        {!isUpdateMessage && (
          <div>
            <Button
              variant="light"
              className="back-button fs-25 mb-3"
              onClick={() => {
                navigate(-1);
              }}
            >
              {left_arrow()} Back
            </Button>
          </div>
        )}
        <div
          className={`w-100 ${
            isUpdateMessage ? "message-write-section-wrapper-update" : ""
          } message-write-section-wrapper  d-flex justify-content-between flex-wrap`}
        >
          <div className="message-write-section p-5">
            <h5>Edit message</h5>
            <hr />
            <div className="variables-section ">
              <h5>Variables</h5>
              {excelData?.excelHeaders?.length > 0 &&
                excelData?.excelHeaders?.map((variable, i) => {
                  return (
                    <Badge
                      pill
                      bg="primary"
                      key={`variable-${i}`}
                      className="me-1 cursor-pointer fw-500"
                      onClick={() => addToTextArea(`{{${variable}}}`)}
                      // onClick={() => {
                      //   setVariableModalShow(true);
                      //   setVariable(variable);
                      // }}
                    >
                      {variable}
                    </Badge>
                  );
                })}
            </div>
            <hr />

            <div className="invitation-message-editor">
              <h5 className="mb-3">Invitation card name</h5>
              <input
                onFocus={() => handleActiveElement("invitationTitleRef")}
                id="invitation-title-input"
                type="text"
                className="w-100 mb-3 invitation-title-input"
                value={invitationTitle}
                onChange={(e) => {
                  dispatch(setInvitationTitle(e.target.value));
                }}
              />
            </div>

            <div className="invitation-message-editor">
              <h5 className="mb-3">Write a message</h5>
              <MessageWriter onFocus={handleActiveElement} />
            </div>
          </div>
          <div className="message-preview-section p-5">
            <h5 className="mb-0">Preview</h5>
            <hr />
            <Preview />
            <div className="mt-3 d-flex flex-column align-items-center">
              {/* Send Invite button :start */}

              {isUpdateMessage ? (
                <>
                  <Button
                    variant="primary"
                    className="fs-16 whatsapp-button d-flex align-items-center"
                    onClick={handleUpdateWhatsappMessage}
                  >
                    <span className="ml-7">Update Message</span>
                    <div className="whatsapp-icon-wrapper">
                      <img
                        src={whatsapp_icon}
                        className="whatsapp-icon"
                        alt="RAPID SENDER – Digital Invitation Sending Platform"
                      />
                    </div>
                  </Button>

                  <Button
                    variant="warning"
                    className="fs-16 mt-2 zip-button text-white d-flex align-items-center justify-content-between"
                    onClick={() => {
                      navigate(`/invitation-status/${invitationId}`);
                    }}
                  >
                    <span className="ml-7">Cancel</span>
                    <div className="zip-icon-wrapper">
                      {right_arrow("36px", "36px")}
                    </div>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="primary"
                    className="fs-16 whatsapp-button d-flex align-items-center"
                    onClick={() => {
                      setCustomerModalShow(true);
                    }}
                  >
                    <span className="ml-7">Send Invite using</span>
                    <div className="whatsapp-icon-wrapper">
                      <img
                        src={whatsapp_icon}
                        className="whatsapp-icon"
                        alt="RAPID SENDER – Digital Invitation Sending Platform"
                      />
                    </div>
                  </Button>

                  {/* Send Invite button :end */}
                  {/* downloadZip button :start */}

                  <Button
                    variant="warning"
                    className="fs-16 mt-2 zip-button text-white d-flex align-items-center"
                    onClick={() => {
                      setModalShow(true);
                      setSecretKey("");
                    }}
                  >
                    <span className="ml-7">Download as ZIP</span>
                    <div className="zip-icon-wrapper">
                      <img src={zip_icon} className="w-100 h-auto" alt="RAPID SENDER – Digital Invitation Sending Platform" />
                    </div>
                  </Button>

                  {/* downloadZip button :end */}
                </>
              )}
            </div>
          </div>
        </div>
        <CaptchaModel
          show={modalShow}
          onHide={() => setModalShow(false)}
          secretKey={secretKey}
          setSecretKey={setSecretKey}
          downloadZip={downloadZip}
        />

        {/* <Modal show={variableModalShow} onHide={() => {setVariableModalShow(false)}} style={{justifyContent: "center", alignItems: "center"}}>
          <Modal.Header closeButton={() => {setVariableModalShow(false)}}>
            <Modal.Title>Variables</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <h5>Select a variable</h5>
                </div>
                <div className="d-flex flex align-items-center gap-2">
                  <Button variant="primary" className="fs-16 mt-2 zip-button text-white d-flex align-items-center" onClick={() => {
                    addToTextArea(`{{${variable}}}`, "cardTitleVariable");
                    // addToTextArea(`{{${variable}}}`);
                    setVariableModalShow(false);
                  }}>
                    <span className="ml-7">Invitation card name</span>
                  </Button>
                  <Button variant="primary" className="fs-16 mt-2 zip-button text-white d-flex align-items-center" onClick={() => {
                      addToTextArea(`{{${variable}}}`, "messageVariable");
                      // addToTextArea(`{{${variable}}}`);
                      setVariableModalShow(false);

                  }}>

                    <span className="ml-7">Message</span>
                  </Button>

                </div>
            </div>
          </Modal.Body>
        </Modal> */}
      </Container>
    </>
  );
};

export default Message;
