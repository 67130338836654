import React from "react";
import brand_logo from "../assets/logo/rapid-sender-logo.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm">
      <div className="container">
          
        <div onClick={()=>navigate("/")} style={{cursor:"pointer", display:"contents"}}>
            <img
              alt="RAPID SENDER – Digital Invitation Sending Platform"
              src={brand_logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
            <div>
              <b className="brand-text fw-600 text-nowrap">RAPID SENDER</b>
              <h1 className="brand-text-description mb-0">
                Digital Invitation Sending Platform
              </h1>
            </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/#features">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#how-it-works">How It Works</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#pricing">Pricing</a>
            </li>
            <li className="nav-item">
              <a className="btn btn-gradient ms-lg-3" href="/send-invitation">
                <i className="fab fa-whatsapp me-2"></i>Start Sending
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
