import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { alignment_icon, delete_icon } from "../../../assets/icons";

const VariableBox = (props) => {
  const {
    isDraggable,
    dragStart,
    header,
    variableData = {},
    index,
    removeDraggedVariable,
    handleAlignmentToggle,
    isDeletable,
    isAlignment,
    handleVariableTextRef = undefined,
    type = "",
    innerIndex,
    bgColor = "",
    onMouseUpDraggedVariable,
    onMouseDownDraggedVariable,
    addMultipleVariableRefs,
    elementForGhost,
    alignmentToggle,
    setAlignmentToggle,
    handleDivClick,
    selectedAlignment,
    setSelectedAlignment,
    // pdfWidth,
    // pdfHeight,
    // pdfFontSize,
    // pdfIconSize,
  } = props;

  const { fontSize } = variableData;

  const variableTextRef = useRef();

  useEffect(() => {
    if (variableTextRef.current && handleVariableTextRef && type === "image") {
      // this method called only once when user drag & drop variable
      handleVariableTextRef(variableTextRef.current, innerIndex);
    }
    if (variableTextRef.current && handleVariableTextRef && type === "pdf") {
      // this method called only once when user drag & drop variable
      handleVariableTextRef(
        variableTextRef.current,
        innerIndex,
        index,
        variableData
      );
    }
    // eslint-disable-next-line
  }, []);

  const renderVariable = useMemo(() => (
    <>
      <div
        className={`variable-box flex align-items-center`}
        // style={{
        //   width: `${pdfWidth}px`,
        //   height: `${pdfHeight}px`,
        // }}
        draggable={isDraggable}
        onDragStart={(e) => {
          e.stopPropagation();
          dragStart(e, header, variableData, index, type);
        }}
        id={`dropped-variable.${index || 0}.${innerIndex}`}
        // {...(addMultipleVariableRefs && { ref: addMultipleVariableRefs })}
        ref={(el) => {
          addMultipleVariableRefs && addMultipleVariableRefs(el);
        }}
        onMouseUp={onMouseUpDraggedVariable}
        onMouseDown={onMouseDownDraggedVariable}
        onDragEnd={(e) => {
          if (
            variableTextRef.current &&
            handleVariableTextRef &&
            type === "image"
          ) {
            handleVariableTextRef(
              variableTextRef.current,
              innerIndex,
              variableData
            );
          }
          if (
            variableTextRef.current &&
            handleVariableTextRef &&
            type === "pdf"
          ) {
            handleVariableTextRef(
              variableTextRef.current,
              innerIndex,
              index,
              variableData
            );
          }
          // this method called when user re-arrange the variable
        }}
      >
        <div className="dots-wrapper d-flex">
          <div className="left-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="right-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
        {isDeletable ? (
          <>
            <div
              className="d-flex justify-content-between flex-grow-1 align-items-center"
              ref={variableTextRef}
            >
              <p className="mb-0" style={{ fontSize: `${fontSize}px` }}>
                {header}
              </p>
              <div className="d-flex align-items-center gap-1">
                {/* <div
                  onClick={() => {
                    handleAlignmentToggle(innerIndex, index);
                  }}
                >
                  {alignment_icon(22)}
                </div> */}
                <div
                  className="me-3"
                  onClick={() => {
                    removeDraggedVariable(innerIndex, index);
                  }}
                >
                  {delete_icon(22)}
                </div>
              </div>
            </div>
          </>
        ) : (
          <p className="mb-0 fs-22">{header}</p>
        )}
      </div>

      {/* {alignmentToggle?.length > 0 &&
        alignmentToggle?.find((item) => item.id === innerIndex)
          ?.isAlignment && (
          <div className="variable-box-alignment-wrapper">
            <div
              className="variable-box-alignment-icon"
              onClick={() => handleDivClick(innerIndex)}
            >
              <input
                type="checkbox"
                className="checkbox-input"
                checked={
                  selectedAlignment?.find((item) => item.id === innerIndex)
                    ?.isAlignment
                }
                readOnly
              />
              <label
                htmlFor="variable-box-alignment-icon-1"
                className="cursor-pointer"
              >
                Variable Alignment
              </label>
            </div>
            <div
              className="variable-box-alignment-icon"
              onClick={() => handleDivClick(innerIndex)}
            >
              <input
                type="checkbox"
                className="checkbox-input"
                checked={selectedAlignment === 2}
                readOnly
              />
              <label
                htmlFor="variable-box-alignment-icon-2"
                className="cursor-pointer"
              >
                Text Alignment
              </label>
            </div>
          </div>
        )} */}
    </>
  ));
  // ), [isDeletable, header, fontSize, variableData, isDraggable]);

  return (
    <div className="variable-box-wrapper custom-scrollbar">
      {renderVariable}
    </div>
  );
};

export default VariableBox;
