import React, { useState } from "react";

const AlertBox = ({ message }) => {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  return (
    <div style={styles.alertBox}>
      <span>⚠️ {message}</span>
      <button style={styles.closeBtn} onClick={() => setVisible(false)}>
        ×
      </button>
    </div>
  );
};

const styles = {
  alertBox: {
    backgroundColor: "#d7f8da",
    color: "#721c24",
    padding: "15px",
    borderRadius: "5px",
    border: "1px solid #d7f8da",
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "350px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    animation: "fadeIn 0.5s ease-in-out",
    zIndex: 9999, // Ensures alert appears above other elements
  },
  closeBtn: {
    background: "none",
    border: "none",
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "#721c24",
  },
};

export default AlertBox;
