import React, { useEffect, useRef, useState } from "react";
import { getInvitationWithStatusById } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvitationWithUsers,
  setIsShowImportExportButtons,
  setIsShowSuccessMessage,
  setIsShowWaitingMessage,
} from "../../redux-slice/statusSlice";
import Status from "./status";
import { useParams } from "react-router-dom";
import {
  listenToInvitationData,
  listenToQRCode,
} from "../../utils/firebase-database";
// import { socket } from "../../config/socket.config";
import { toast } from "react-hot-toast";
import { setExcelData } from "../../redux-slice/filesSlice";
import { onValue, ref } from "firebase/database";
import { database } from "../../config/firebase";
import { WaitingMessage } from "../customer/waitingMessage";
import SuccessMessage from "../successMessage";
import { usePDFContext } from "../../context/Context";
import {
  setInvitationTitle,
  setTextMessage,
} from "../../redux-slice/messageSlice";

const Invitation = () => {
  const [user, setUser] = useState({
    loggedIn: false,
    loggedOut: false,
  });
  const [invitationWithUsers_, setInvitationWithUsers_] = useState();
  // const { invitationId, setShort_id } = usePDFContext();

  const params = useParams();
  const invitationId = params?.invitationId;

  const {
    isShowImportExportButtons,
    isShowWaitingMessage,
    isShowSuccessMessage,
  } = useSelector((state) => state.status);

  const { setAlternateNumber } = usePDFContext();

  const [qrCode, setQrCode] = useState({
    string: "",
    isLoading: false,
  });
  const [currentRow, setCurrentRow] = useState(-1);
  const [newExcelData, setNewExcelData] = useState({
    headers: [],
    values: [],
  });

  const [qrCodeScanned, setQrCodeScanned] = useState(false);

  const dispatch = useDispatch();
  const messageToast = {
    toast,
  };

  // useEffect(() => {
  //   setShort_id(shortId);
  // }, [shortId]);

  const { invitationWithUsers } = useSelector((state) => state.status);
  const { excelData } = useSelector((state) => state.files);
  const [screenshot, setScreenshot] = useState("");

  const invitationWithUsersRef = useRef(null);

  useEffect(() => {
    if (invitationWithUsers) {
      invitationWithUsersRef.current = invitationWithUsers;
    }
  }, [invitationWithUsers]);

  useEffect(() => {
    invitationId && handleGetInvitationStatus(invitationId);
    //   invitationId && initializeSocket(socket);
  }, []);

  // useEffect(() => {
  //   invitationId && handleGetInvitationStatus(invitationId);
  //   //   invitationId && initializeSocket(socket);
  // }, [invitationId]);

  // firebase QR code listener
  useEffect(() => {
    listenToInvitationData(invitationId, (data) => {
      // dispatch(setIsShowWaitingMessage(data?.isPayment));
      dispatch(setIsShowWaitingMessage(!data?.isPayment));
      setQrCode({
        string: data?.qrCode,
        isLoading: false,
      });
    });

    const qrCodeLoggedIn = ref(
      database,
      `invitations/${invitationId}/qrCodeScanned`
    );
    const qrCodeLoggedInUnsubscribe = onValue(qrCodeLoggedIn, (snapshot) => {
      const qrCodeScanned = snapshot.val();
      if (qrCodeScanned) {
        setQrCodeScanned(true);
      }
    });

    return () => {
      qrCodeLoggedInUnsubscribe();
    };
  }, [invitationId]);

  // useEffect(() => {
  //   if (invitationId) {
  //     // Listen to invitation status changes
  //     const statusRef = ref(database, `invitations/${invitationId}/status`);

  //     const statusUnsubscribe = onValue(statusRef, (snapshot) => {
  //       const data = snapshot.val();
  //       if (data) {
  //         updateStatus(data);
  //       }
  //     });

  //     c

  //     // Listen to QR code changes
  //     const qrCodeRef = ref(database, `invitations/${invitationId}/qrCode`);
  //     const qrCodeUnsubscribe = onValue(qrCodeRef, (snapshot) => {
  //       const qrCodeData = snapshot.val();
  //       setQrCode({
  //         string: qrCodeData?.code || '',
  //         isLoading: qrCodeData?.isLoading || false
  //       });
  //     });

  //     // Listen to current user changes
  //     const currentUserRef = ref(database, `invitations/${invitationId}/currentUser`);
  //     const currentUserUnsubscribe = onValue(currentUserRef, (snapshot) => {
  //       const currentUser = snapshot.val();
  //       if (currentUser) {
  //         updateCurrentUserRow(currentUser);
  //       }
  //     });

  //     // Listen to user authentication status
  //     const authStatusRef = ref(database, `invitations/${invitationId}/authStatus`);
  //     const authStatusUnsubscribe = onValue(authStatusRef, (snapshot) => {
  //       const authStatus = snapshot.val();
  //       if (authStatus?.loggedOut) {
  //         dispatch(setIsShowImportExportButtons(true));
  //         dispatch(setIsShowSuccessMessage(true));
  //         setUser({
  //           loggedIn: false,
  //           loggedOut: true,
  //         });
  //       }
  //       if (authStatus?.qrScanned) {
  //         setUser({
  //           loggedIn: true,
  //         });
  //         setQrCode({
  //           string: "",
  //           isLoading: false,
  //         });
  //       }
  //     });

  //     // Listen to screenshot updates
  //     const screenshotRef = ref(database, `invitations/${invitationId}/screenshot`);
  //     const screenshotUnsubscribe = onValue(screenshotRef, (snapshot) => {
  //       const screenshotData = snapshot.val();
  //       if (screenshotData) {
  //         setScreenshot(screenshotData);
  //       }
  //     });

  //     // Cleanup listeners
  //     return () => {
  //       statusUnsubscribe();
  //       qrCodeUnsubscribe();
  //       currentUserUnsubscribe();
  //       authStatusUnsubscribe();
  //       screenshotUnsubscribe();
  //     };
  //   }
  // }, [invitationId]);

  // const initializeSocket = (socket) => {
  //   socket.on("invitationStatus", function (data) {
  //     updateStatus(data);
  //   });
  //   socket.on("qrCode", function (data) {
  //     setQrCode({
  //       string: data?.qrCode,
  //       isLoading: false,
  //     });
  //   });
  //   socket.on("logout", function (data) {
  //     dispatch(setIsShowImportExportButtons(true));
  //     dispatch(setIsShowSuccessMessage(true));
  //     setUser({
  //       loggedIn: false,
  //       loggedOut: true,
  //     });
  //   });

  //   socket.on("isQrLoading", function (value) {
  //     setQrCode({
  //       isLoading: value,
  //     });
  //   });
  //   socket.on("currentUser", (currentUser) => {
  //     updateCurrentUserRow(currentUser);
  //   });
  //   socket.on("message", ({ type, message }) => {
  //     messageToast.toast[type](message, {
  //       duration: 3000,
  //     });
  //   });
  //   socket.on("qrScanned", function (value) {
  //     if (value) {
  //       setUser({
  //         loggedIn: true,
  //       });
  //       setQrCode({
  //         string: "",
  //         isLoading: false,
  //       });
  //     }
  //   });
  //   socket.on("screenshot", (screenshot) => {
  //     setScreenshot(screenshot);
  //   });

  //   socket.on("isPaymentDone", (isPayment) => {
  //     isPayment && dispatch(setIsShowWaitingMessage(false));
  //   });
  // };

  // ... existing code ...

  // useEffect(() => {
  //   if (invitationId) {
  //     // Listen to invitation status changes
  //     const statusRef = ref(database, `invitations/${invitationId}/status`);
  //     const statusUnsubscribe = onValue(statusRef, (snapshot) => {
  //       const data = snapshot.val();
  //       if (data) {
  //         updateStatus(data);
  //       }
  //     });

  //     // Listen to QR code changes
  //     // const qrCodeRef = ref(database, `invitations/${invitationId}/qrCode`);
  //     // const qrCodeUnsubscribe = onValue(qrCodeRef, (snapshot) => {
  //     //   const qrCodeData = snapshot.val();
  //     //   setQrCode({
  //     //     string: qrCodeData?.code || '',
  //     //     isLoading: qrCodeData?.isLoading || false
  //     //   });
  //     // });

  //     // Listen to current user changes

  //     // const usersId = invitationWithUsersRef.current?.users?.map((user)=>user?._id);
  //     // usersId?.forEach((userId)=>{
  //     //   const userStatusRef =ref(database,`invitations/${invitationId}/users/${userId}`);
  //     //   console.log("::::::userStatusRef---",userStatusRef);
  //     //   const unsubscribe = onValue(userStatusRef, (snapshot) => {
  //     //     const data = snapshot.val();
  //     // console.log("::::::data---", data);
  //     // if (data) {
  //     //   // Assuming data contains both userId and status
  //     //   const { userId, status } = data;

  //     //   console.log("::::::userId---",userId);
  //     //   updateCurrentUserRow({ userId, status });
  //     // }
  //     //   });
  //     //   return () => unsubscribe();
  //     // })

  //     // const currentUserRef = ref(
  //     //   database,
  //     //   `invitations/${invitationId}/currentUser`
  //     // );
  //     // const currentUserUnsubscribe = onValue(currentUserRef, (snapshot) => {
  //     //   const currentUser = snapshot.val();
  //     //   if (currentUser) {
  //     //     updateCurrentUserRow(currentUser);
  //     //   }
  //     // });

  //     const currentUserRef = ref(
  //       database,
  //       `invitations/${invitationId}/currentUser`
  //     );
  //     const currentUserUnsubscribe = onValue(currentUserRef, (snapshot) => {
  //       const currentUser = snapshot.val();
  //       if (currentUser) {
  //         console.log("currentUser---000", currentUser);
  //         updateCurrentUserRow(currentUser);
  //       }
  //     });

  //     // // Listen to user authentication status
  //     // const authStatusRef = ref(database, `invitations/${invitationId}/authStatus`);
  //     // const authStatusUnsubscribe = onValue(authStatusRef, (snapshot) => {
  //     //   const authStatus = snapshot.val();
  //     //   if (authStatus?.loggedOut) {
  //     //     dispatch(setIsShowImportExportButtons(true));
  //     //     dispatch(setIsShowSuccessMessage(true));
  //     //     setUser({
  //     //       loggedIn: false,
  //     //       loggedOut: true,
  //     //     });
  //     //   }
  //     //   if (authStatus?.qrScanned) {
  //     //     setUser({
  //     //       loggedIn: true,
  //     //     });
  //     //     setQrCode({
  //     //       string: "",
  //     //       isLoading: false,
  //     //     });
  //     //   }
  //     // });

  //     // Listen to screenshot updates
  //     const screenshotRef = ref(
  //       database,
  //       `invitations/${invitationId}/screenshot`
  //     );
  //     const screenshotUnsubscribe = onValue(screenshotRef, (snapshot) => {
  //       const screenshotData = snapshot.val();
  //       if (screenshotData) {
  //         setScreenshot(screenshotData);
  //       }
  //     });

  //     // Cleanup listeners
  //     return () => {
  //       statusUnsubscribe();
  //       // qrCodeUnsubscribe();
  //       currentUserUnsubscribe();

  //       // authStatusUnsubscribe();
  //       screenshotUnsubscribe();
  //       // paymentUnsubscribe();
  //     };
  //   }
  // }, [invitationId, invitationWithUsersRef]);

  useEffect(() => {
    if (invitationId) {
      // Listen to invitation status changes
      const statusRef = ref(database, `invitations/${invitationId}/status`);
      const statusUnsubscribe = onValue(statusRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          updateStatus(data);
        }
      });
      // Listen to QR code changes
      // const qrCodeRef = ref(database, `invitations/${invitationId}/qrCode`);
      // const qrCodeUnsubscribe = onValue(qrCodeRef, (snapshot) => {
      //   const qrCodeData = snapshot.val();
      //   setQrCode({
      //     string: qrCodeData?.code || '',
      //     isLoading: qrCodeData?.isLoading || false
      //   });
      // });
      // Listen to current user changes
      // const usersId = invitationWithUsersRef.current?.users?.map((user)=>user?._id);
      // usersId?.forEach((userId)=>{
      //   const userStatusRef =ref(database,`invitations/${invitationId}/users/${userId}`);
      //   console.log("::::::userStatusRef---",userStatusRef);
      //   const unsubscribe = onValue(userStatusRef, (snapshot) => {
      //     const data = snapshot.val();
      // console.log("::::::data---", data);
      // if (data) {
      //   // Assuming data contains both userId and status
      //   const { userId, status } = data;
      //   console.log("::::::userId---",userId);
      //   updateCurrentUserRow({ userId, status });
      // }
      //   });
      //   return () => unsubscribe();
      // })
      const currentUserRef = ref(
        database,
        `invitations/${invitationId}/currentUser`
      );
      const currentUserUnsubscribe = onValue(currentUserRef, (snapshot) => {
        const currentUser = snapshot.val();
        if (currentUser) {
          updateCurrentUserRow(currentUser);
        }
      });

      // // Listen to user authentication status
      // const authStatusRef = ref(database, `invitations/${invitationId}/authStatus`);
      // const authStatusUnsubscribe = onValue(authStatusRef, (snapshot) => {
      //   const authStatus = snapshot.val();
      //   if (authStatus?.loggedOut) {
      //     dispatch(setIsShowImportExportButtons(true));
      //     dispatch(setIsShowSuccessMessage(true));
      //     setUser({
      //       loggedIn: false,
      //       loggedOut: true,
      //     });
      //   }
      //   if (authStatus?.qrScanned) {
      //     setUser({
      //       loggedIn: true,
      //     });
      //     setQrCode({
      //       string: "",
      //       isLoading: false,
      //     });
      //   }
      // });
      // Listen to screenshot updates
      const screenshotRef = ref(
        database,
        `invitations/${invitationId}/screenshot`
      );
      const screenshotUnsubscribe = onValue(screenshotRef, (snapshot) => {
        const screenshotData = snapshot.val();
        if (screenshotData) {
          setScreenshot(screenshotData);
        }
      });

      // Cleanup listeners
      return () => {
        statusUnsubscribe();
        // qrCodeUnsubscribe();
        currentUserUnsubscribe();

        // authStatusUnsubscribe();
        screenshotUnsubscribe();
        // paymentUnsubscribe();
      };
    }
  }, [invitationId, invitationWithUsersRef]);

  const updateCurrentUserRow = (currentUser) => {
    const clonedInvitationWithUsersRef = {
      ...invitationWithUsersRef.current,
      users: [...invitationWithUsersRef?.current?.users],
    };

    const currentUserIndex = clonedInvitationWithUsersRef.users.findIndex(
      (user) => user._id === currentUser.userId
    );

    setCurrentRow(currentUserIndex);
  };

  const updateStatus = (data) => {
    const clonedInvitationWithUsersRef = {
      ...invitationWithUsersRef.current,
      users: [...invitationWithUsersRef?.current?.users],
    };
    const currentUserIndex = clonedInvitationWithUsersRef.users.findIndex(
      (user) => user._id === data.user
    );
    clonedInvitationWithUsersRef.users[currentUserIndex] = {
      ...clonedInvitationWithUsersRef.users[currentUserIndex],
      status: data.status,
    };
    invitationWithUsersRef.current = clonedInvitationWithUsersRef;
    setInvitationWithUsers_(clonedInvitationWithUsersRef);

    // Update ExcelData
    // const clonedExcelData = JSON.parse(JSON.stringify(excelData));
    // const index = clonedExcelData.guestData.findIndex(
    //   (guest) => guest._id === data.user
    // );
    // clonedExcelData.guestData[index] = {
    //   ...clonedExcelData.guestData[index],
    //   status: data.status,
    // };
    // dispatch(setExcelData(clonedExcelData));
  };

  const handleGetInvitationStatus = async (id) => {
    try {
      const res = await getInvitationWithStatusById(id);
      // const qrCode = res?.data?.result?.qrCode || "";
      // if (!!qrCode) {
      //   setQrCode({ string: qrCode });
      // }
      if (!res) {
        toast.error(`Invitation not found`);
      }
      dispatch(setInvitationWithUsers(res.data.result));
      setInvitationWithUsers_(res.data.result);
      dispatch(setTextMessage(res.data.result.message));
      dispatch(setInvitationTitle(res.data.result.invitationTitle));
      setAlternateNumber(res.data.result.alternateNumber);
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    }
  };

  const filterUsersByStatus = (status) => {
    const users = invitationWithUsersRef?.current?.users;
    const filteredUsers =
      users?.length > 0 ? users.filter((user) => user.status === status) : [];
    return filteredUsers;
  };

  const sortArray = (objectsArray, keysArray) => {
    const sortedObjectsArray = objectsArray.map((obj) => {
      const sortedObj = {};
      keysArray.forEach((key) => {
        sortedObj[key] = obj[key];
      });
      return sortedObj;
    });
    return sortedObjectsArray;
  };

  const handleNewExcelData = () => {
    let headers = excelData?.excelHeaders;
    if (headers?.length > 0) {
      headers = [...headers];
      let values = [];
      // const usersWithFailedStatus = filterUsersByStatus("failed");
      const usersWithFailedStatus = invitationWithUsersRef?.current?.users;
      usersWithFailedStatus?.length > 0 &&
        usersWithFailedStatus?.map((user) => {
          const modifiedUser = {
            // _id: user._id,
            ...user.details,
            status: user.status,
          };
          values.push(modifiedUser);
        });
      values = sortArray(values, headers);
      setNewExcelData({ ...newExcelData, headers, values });
    }
  };

  useEffect(() => {
    invitationWithUsers?.payment && dispatch(setIsShowWaitingMessage(false));
  }, [invitationWithUsers]);

  useEffect(() => {
    if (excelData && invitationWithUsersRef?.current) {
      handleNewExcelData();
    }
  }, [excelData, invitationWithUsersRef?.current]);

  return (
    <div className="h-100 ">
      {/* removed payment information!!!!! */}
      {/* {isShowWaitingMessage ? (
        <div className="p-20 w-100 d-flex justify-content-center align-items-center h-100">
          <WaitingMessage />
        </div>
      ) : isShowSuccessMessage ? ( */}
      {isShowSuccessMessage ? (
        <div className="p-20 w-100 d-flex justify-content-center align-items-center h-100">
          <SuccessMessage id={invitationId} />
        </div>
      ) : (
        invitationId &&
        invitationWithUsersRef?.current && (
          <Status
            invitationWithUsers={invitationWithUsersRef.current}
            qrCode={qrCode}
            user={user}
            currentRow={currentRow}
            newExcelData={newExcelData}
            screenshot={screenshot}
            qrCodeScanned={qrCodeScanned}
          />
        )
      )}
    </div>
  );
};

export default Invitation;
