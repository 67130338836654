export const addFieldToArrayObject = (array, fieldName, fieldValue) => {
  const updatedArray = array?.map((obj) => {
    return { ...obj, [fieldName]: fieldValue };
  });
  return updatedArray;
};

export const checkFieldAvailability = (array, fieldName) => {
  const isAvailable =
    array?.length > 0 && array?.every((obj) => fieldName in obj);

  return isAvailable;
};

export const remove = ()=>{

}