import domtoimage from "dom-to-image";
import styles from "../assets/styles/RenderHTML.module.css";

export const RenderHTML = ({ html }) => {
  let htmlStr = html;
  const regex = /([0-9]*[.])?[0-9]+(?=(px))/gi;
  const pxs = html.match(regex);
  const windowWidth = window.innerWidth || {};

  if (windowWidth && pxs?.length > 0) {
    pxs.map((px) => {
      let newUnit = (100 * px) / windowWidth;
      newUnit = newUnit.toPrecision(2);
      const newStr = `min(${newUnit}vw, ${px}px)`;
      htmlStr = htmlStr.replace(px + "px", newStr);
    });
  }
  return (
    <div
      className={`${styles.rederHTML}`}
      dangerouslySetInnerHTML={{
        __html: htmlStr,
      }}
    ></div>
  );
};

export const convertNodeToBase64 = (node) => {
  return new Promise((resolve) => {
    
    node.style.width = "200px";
    node.style.height = "100px";
    try {
      domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          return resolve(dataUrl);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
          return resolve(null);
        });
    } catch (error) {
      console.log(error);
      return resolve(null);
    }
  });
};
