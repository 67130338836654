import React, { memo } from "react";
import { Document, Page } from "react-pdf";
import Loader from "../../loader";

const PreviewPdf = (props) => {
  const { pageNo, pdfBuffer, url = "" } = props;

  return (
    <Document file={url ? url : { data: pdfBuffer }} loading={<Loader />}>
      <Page pageNumber={pageNo} />
    </Document>
  );
};

export default memo(PreviewPdf);
