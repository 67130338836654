import React from "react";
import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";

const RefundPage = () => {

  return (
    <div style={{ paddingTop: "76px"}}>
      <Navbar />
        <div class="container mt-5" style={{ paddingBottom: "76px"}}>
          <h1 className="h1">Refund Policy</h1>
          <p>Last Updated: 31st Jan 2024.</p>
          
          <h2 className="h3">1. Introduction</h2>
          <p>At Rapid Sender, we strive to provide excellent service. This refund policy explains the conditions under which refunds are granted.</p>
          
          <h2 className="h3">2. Eligibility for Refunds</h2>
          <p>We offer refunds only under the following conditions:</p>
          <ul>
              <li>If you were charged incorrectly due to a billing error.</li>
              <li>If you purchased a plan but did not receive the promised service.</li>
              <li>In case of duplicate transactions.</li>
          </ul>
          
          <h2 className="h3">3. Non-Refundable Cases</h2>
          <p>Refunds will not be issued in the following cases:</p>
          <ul>
              <li>Change of mind after purchasing a plan.</li>
              <li>Partial usage of services.</li>
              <li>Violation of our Terms & Conditions.</li>
          </ul>
          
          <h2 className="h3">4. Refund Process</h2>
          <p>To request a refund, please contact us within 7 days of the transaction with proof of purchase. Once your request is approved, the refund will be processed within 5-10 business days.</p>
          
          <h2 className="h3">5. Contact Us</h2>
          <p>If you have any questions regarding our refund policy, reach out to us:</p>
          <ul>
              <li>Email: <a href="mailto:rapid.sender.56@gmail.com
">rapid.sender.56@gmail.com
</a></li>
              <li>Phone: +91 96248 28267</li>
              <li>Address: Surat, Gujarat, India</li>
          </ul>
        </div>
      {/* <CallToAction /> */}
      <Footer />
    </div>
  );
};

export default RefundPage;
