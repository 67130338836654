export const fileToDataURL = async (stream) => {
  const filePromise = new Promise((resolve, reject) => {
    try {
      const file = stream;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        resolve(event.target.result);
      };
    } catch (error) {
      reject(error);
    }
  });
  return await filePromise;
};

export const dataURLtoFile = (dataURL = "") => {
  if (!dataURL) return;
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], "uploaded-file", { type: mime });
};

export const fileToBuffer = async (file) => {
  const bufferPromise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const buffer = reader.result;
        resolve(buffer);
      };
    } catch (error) {
      reject(error);
    }
  });
  return await bufferPromise;
};

export const dataURLtoBuffer = async (dataURL) => {
  const fileStream = dataURLtoFile(dataURL);
  const buffer = await fileToBuffer(fileStream);
  return buffer;
};
