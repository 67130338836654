import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { RenderHTML, dataURLtoBuffer, dataURLtoFile } from "../../../utils";
import "./index.scss";
import PreviewPdf from "../../variableInsertion/previewPdf";
import { getFileStreamFromIndexDBAPI } from "../../../indexDB/getFromIndexDB";
import { usePDFContext } from "../../../context/Context";

const Preview = () => {
  const { excelData, fileType } = useSelector((state) => state.files);
  const { textMessage } = useSelector((state) => state.message);
  const { pdfData, setPdfData } = usePDFContext();

  const [styledTextMessage, setStyledTextMessage] = useState("");

  const [fileStream, setFileStream] = useState("");
  // const [pdfData, setPdfData] = useState("");

  const [imgUrl, setImgUrl] = useState("");
  const [pdfBuffer, setPdfBuffer] = useState();

  const addStylesToTextMessage = () => {
    let clonedTextMessage = JSON.parse(JSON.stringify(textMessage));
    // const boldText = textMessage.match(/\*([^\*]+)\*/g);
    // const italicText = textMessage.match(/_([^_]+)_/g);
    // const strikeText = textMessage.match(/\~([^\~]+)\~/g);

    const boldPattern = new RegExp(/\*([^\*]+)\*/g);
    const italicPattern = new RegExp(/_([^_]+)_/g);
    const strikePattern = new RegExp(/\~([^\~]+)\~/g);
    const variablesPattern = new RegExp(/{{(.+?)}}/g);

    clonedTextMessage = clonedTextMessage.replace(
      boldPattern,
      function (match, word) {
        // Modify the word here
        return "<b>" + word + "</b>"; // Convert the word to bold
      }
    );
    clonedTextMessage = clonedTextMessage.replace(
      italicPattern,
      function (match, word) {
        // Modify the word here
        return "<i>" + word + "</i>"; // Convert the word to italic
      }
    );
    clonedTextMessage = clonedTextMessage.replace(
      strikePattern,
      function (match, word) {
        // Modify the word here
        return "<strike>" + word + "</strike>"; // Convert the word to strike
      }
    );
    clonedTextMessage = clonedTextMessage.replace(
      variablesPattern,
      function (match, word) {
        // Modify the word here
        return excelData?.guestData[0][word]; // Convert the variable to dynamic value
      }
    );
    setStyledTextMessage("<pre>" + clonedTextMessage + "</pre>");
  };

  useEffect(() => {
    if (pdfData) {
      getPdfBuffer();
    }
  }, [pdfData]);

  const getPdfBuffer = async () => {
    const buffer = await dataURLtoBuffer(pdfData);
    setPdfBuffer(buffer);
  };

  useEffect(() => {
    addStylesToTextMessage();
  }, [textMessage]);

  useEffect(() => {
    getFileStreamFromIndexDB(fileType);
  }, [fileType, pdfData]);

  const getFileStreamFromIndexDB = async (fileType) => {
    try {
      if (fileType === "image") fileType = "imageFile";
      if (fileType === "pdf") fileType = "pdfFile";
      const fileObject = await getFileStreamFromIndexDBAPI(fileType);
      if (fileObject?.imageStream) {
        setFileStream(fileObject.imageStream);
        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.imageStream))
            : null;
        setImgUrl(imgUrl);
      }
      if (fileObject?.pdfStream) {
        setPdfData(fileObject.pdfStream);
        const imgUrl =
          fileStream !== null
            ? URL.createObjectURL(dataURLtoFile(fileObject.pdfStream))
            : null;
        setImgUrl(imgUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card className="invitation-message-preview overflow-hidden">
      <div className="image-preview-wrapper">
        {imgUrl && fileType === "image" ? (
          <div className="invitation-preview">
            <Card.Img variant="top" src={imgUrl} />
          </div>
        ) : (
          <div className="invitation-preview">
            <PreviewPdf pdfBuffer={pdfBuffer} pageNo={1} />
          </div>
        )}
      </div>
      <Card.Body className="invitation-message-wrapper p-0 px-1">
        <Card.Text className="invitation-message">
          <RenderHTML html={styledTextMessage} />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Preview;
