import { Button, Offcanvas, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import brand_logo from "../../assets/logo/rapid-sender-logo.png";
import "./index.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { profile_icon, right_arrow } from "../../assets/icons";
import { usePDFContext } from "../../context/Context";
import { setExcelData, setFileStream } from "../../redux-slice/filesSlice";
import { useDispatch } from "react-redux";
import rapidDB from "../../indexDB";
import { invitationPendingStatus } from "../../api/api";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import {
  setInvitationTitle,
  setTextMessage,
} from "../../redux-slice/messageSlice";

const NavbarComponent = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { pdfData, setPdfData, isUploadExcel, setIsUploadExcel } =
    usePDFContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(50);

  const currentLocation = location.pathname;
  const invitationId = params?.invitationId;

  const handleRestart = async () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/"); // Navigate to the home page
    setPdfData("");
    setIsUploadExcel(false);
    dispatch(
      setExcelData({
        guestData: [{ name: "", mobile: "" }],
        excelHeaders: ["name", "mobile"],
      })
    );
    await rapidDB.files.clear();
    dispatch(setInvitationTitle(""));
    dispatch(setTextMessage(""));
  };

  const handlePendingStatus = async () => {
    if (invitationId) {
      setIsLoading(true);
      try {
        const response = await invitationPendingStatus(invitationId);
        toast.success("Invitation status updated successfully");
      } catch (error) {
        console.error("Error updating invitation status:", error);
        toast.error("Failed to update invitation status");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      alert("Please enter a valid amount");
      return;
    }

    // Check if Razorpay is loaded
    if (typeof window.Razorpay === "undefined") {
      alert("Razorpay SDK is not loaded. Please try again.");
      return;
    }

    const options = {
      key: "rzp_test_D7KIIKTqzzUJhj", // Replace with your Razorpay Key ID
      amount: amount * 100, // Razorpay uses the smallest currency unit (e.g., paise for INR)
      currency: "INR",
      name: "Rapid Sender",
      description: "Payment for Rapid Sender",
      image: brand_logo, // Optional
      handler: (response) => {
        console.log(response, "response");
        // alert(
        //   `Payment successful! Payment ID: ${response.razorpay_payment_id}`
        // );

        toast.success("Payment successful!");
      },
      prefill: {
        name: "John Doe",
        email: "johndoe@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  return (
    // <Navbar bg="white" expand="md" className="p-0" sticky="top">
    //   <Container fluid>
    //     <Navbar.Brand
    //       className="rapid-sender-logo"
    //       onClick={() => navigate("/")}>
    //       <img
    //         alt="RAPID SENDER – Digital Invitation Sending Platform"
    //         src={brand_logo}
    //         width="60"
    //         height="60"
    //         className="d-inline-block align-top"
    //       />
    //     </Navbar.Brand>
    //     <b className="brand-text fw-700 text-nowrap">RAPID SENDER</b>
    //     <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
    //     <Navbar.Offcanvas
    //       id={`offcanvasNavbar-expand-md`}
    //       className="flex-grow-0"
    //       aria-labelledby={`offcanvasNavbarLabel-expand-md`}
    //       placement="end">
    //       <Offcanvas.Header closeButton>
    //         <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
    //           Offcanvas
    //         </Offcanvas.Title>
    //       </Offcanvas.Header>
    //       <Offcanvas.Body>
    //         {currentLocation === "/" && (
    //           <Nav className="justify-content-end mr-182">
    //             <Nav.Link href="/" className="fs-22 p-0 mr-37">
    //               App
    //             </Nav.Link>
    //             <Nav.Link href="/privacy" className="fs-22 p-0 mr-37">
    //               Privacy
    //             </Nav.Link>
    //             <Nav.Link href="/terms" className="fs-22 p-0">
    //               Terms
    //             </Nav.Link>
    //           </Nav>
    //         )}
    //         {currentLocation === "/sign-in" ||
    //           (currentLocation === "/sign-up" && (
    //             <Nav className="justify-content-end mr-213 align-items-center">
    //               <Nav.Link className="fs-22 p-0 mr-37">Login</Nav.Link>
    //               <Button variant="primary" className="fs-18">
    //                 Sign up Free {right_arrow("24px", "24px")}
    //               </Button>
    //             </Nav>
    //           ))}
    //         {(currentLocation === "/send-invitation" ||
    //           currentLocation === "/variable" ||
    //           currentLocation === "/invitation-status" ||
    //           currentLocation === `/invitation-status/${invitationId}` ||
    //           currentLocation === "/invitation-message") && (
    //           <Nav className="justify-content-end mr-213 align-items-center">
    //             <Nav.Link className="p-0 mr-37">{profile_icon()}</Nav.Link>
    //           </Nav>
    //         )}
    //       </Offcanvas.Body>
    //     </Navbar.Offcanvas>
    //   </Container>
    // </Navbar>

    <Navbar bg="white" expand="md" className="p-0 navbar-main" sticky="top">
      <Container>
        <div className="navbar-container landing-page-navbar-container">
          <Navbar.Brand
            className="rapid-sender-logo cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              alt="RAPID SENDER – Digital Invitation Sending Platform"
              src={brand_logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
            <div>
              <b className="brand-text fw-600 text-nowrap">RAPID SENDER</b>
              <p className="brand-text-description mb-0">
                Digital Invitation Sending Platform
              </p>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            className="flex-grow-0"
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                Rapid Sender
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {currentLocation === "/" && (
                <div className="d-flex justify-content-between offcanvas-navbar">
                  <Nav className="justify-content-end mr-182 offcanvas-navbar-nav">
                    <Link
                      to="/"
                      className="fs-18 p-0 mr-37 text-decoration-none link-color"
                    >
                      App
                    </Link>
                    <Link
                      to="/privacy"
                      className="fs-18 p-0 mr-37 text-decoration-none link-color"
                    >
                      Privacy
                    </Link>
                    <Link
                      to="/terms"
                      className="fs-18 p-0 text-decoration-none link-color"
                    >
                      Terms
                    </Link>
                  </Nav>
                  <div className="offcanvas-login-button">
                    <Button
                      variant="primary"
                      className="fs-18 px-4 py-1 login-button"
                      onClick={() => {
                        navigate("/send-invitation");
                        setIsUploadExcel(false);
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              )}
              {currentLocation === "/sign-in" ||
                (currentLocation === "/sign-up" && (
                  <Nav className="justify-content-end mr-213 align-items-center">
                    <Link to="/sign-in" className="fs-22 p-0 mr-37">
                      Login
                    </Link>
                    <Button variant="primary" className="fs-18">
                      Sign up Free {right_arrow("24px", "24px")}
                    </Button>
                  </Nav>
                ))}

              <div className="d-flex gap-3">
                {(currentLocation === "/send-invitation" ||
                  currentLocation === "/variable" ||
                  currentLocation === "/invitation-status" ||
                  currentLocation === `/invitation-status/${invitationId}` ||
                  currentLocation === "/invitation-message") && (
                  <>
                    <Nav className="justify-content-end align-items-center">
                      <Link
                        to="/"
                        className="p-0"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default anchor behavior
                          handleRestart();
                        }}
                      >
                        {/* {profile_icon()} */}
                        🔁 Restart the process
                      </Link>
                    </Nav>
                    {/* <Button className="payment-button" onClick={handlePayment}>
                      Payment
                    </Button> */}
                  </>
                )}

                {/* {(currentLocation === "/invitation-status" ||
                  currentLocation === `/invitation-status/${invitationId}`) && (
                  <div className="offcanvas-login-button">
                    <Button
                      variant="primary"
                      className="fs-12 py-1 login-button"
                      onClick={handlePendingStatus}
                    >
                      {isLoading ? "Updating..." : "Pending status"}
                    </Button>
                  </div>
                )} */}
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
