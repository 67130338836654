import React, { useState } from "react";
import { db, collection, addDoc } from "../config/firebase";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AlertBox from "../components/AlertBox";
import CallToAction from "../components/CallToAction";

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, "contactMessages"), formData);
      setAlertMessage("Message sent successfully!");
      setFormData({ name: "", email: "", message: "" });

      setTimeout(() => setAlertMessage(""), 3000); // Hide alert after 3 seconds
    } catch (error) {
      setAlertMessage("Failed to send message. Please try again.");
      setTimeout(() => setAlertMessage(""), 3000);
    }
  };

  return (
    <div style={{ paddingTop: "76px" }}>
      <Navbar />
      {alertMessage && <AlertBox message={alertMessage} />} {/* Show alert only when message exists */}
      <div className="container mt-5" style={{ paddingBottom: "76px" }}>
        <h1>Contact Us</h1>
        <p>Have any questions? Reach out to us!</p>

        <form onSubmit={handleSubmit} style={{ maxWidth: "500px"}}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              name="name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea
              name="message"
              className="form-control"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary mt-3">Send</button>
        </form>
      </div>
      <CallToAction /> 
      <Footer />
    </div>
  );
};

export default ContactUsPage;
