import React from "react";

const HeroSection = () => {
  return (
    <header className="min-vh-100 d-flex align-items-center bg-light" style={{ paddingTop: "76px" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <span className="badge bg-primary mb-3 px-3 py-2 rounded-pill">
              <i className="fab fa-whatsapp me-2"></i>WhatsApp Automation
            </span>
            <h1 className="display-4 fw-bold mb-4 lh-3">
              Send Invitations via WhatsApp Automatically
            </h1>
            <p className="lead mb-4">
              Automate your event invitations through WhatsApp. Reach your guests instantly with personalized messages, track responses, and manage RSVPs effortlessly.
            </p>
            <div className="d-flex gap-3">
              <a href="/send-invitation" className="btn btn-gradient btn-lg px-4">
                <i className="fas fa-paper-plane me-2"></i>Start Sending
              </a>
              <a href="https://www.youtube.com/watch?v=xgHEIHIGx5g" className="btn btn-outline-warning btn-lg px-4">
                <i className="fas fa-play me-2"></i>Watch Demo
              </a>
            </div>
            <div className="mt-4 d-flex align-items-center gap-4">
              <div className="d-flex align-items-center">
                <i className="fas fa-check-circle text-warning me-2"></i>
                <span>Instant Delivery</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-check-circle text-warning me-2"></i>
                <span>Bulk Sending</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fas fa-check-circle text-warning me-2"></i>
                <span>Personalisation</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero-mockup">
              <img src="invitation-process.gif" alt="WhatsApp Invitation Dashboard" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeroSection;
