import { uploadAllDataToDb } from "../api/api";

export const createInvitation = async (
  link,
  guests,
  coordinates,
  mimeType,
  message,
  contact,
  invitationTitle
) => {
  try {
    const payload = {
      link,
      guests,
      coordinates,
      mimeType,
      message,
      contact,
      invitationTitle,
    };

    const res = await uploadAllDataToDb(payload);

    const invitation_id = res?.data?.invitation_id;
    // const short_id = res?.data?.shortId;

    // return { invitation_id, short_id };
    return invitation_id;
  } catch (error) {
    console.log("createInvitation-error", error);
  }
};
