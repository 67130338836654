import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storedDraggedVariables: [],
  pdfDraggedVariables: {},
};

const variablesSlice = createSlice({
  name: "variables",
  initialState,
  reducers: {
    setStoredDraggedVariables: (state, action) => {
      state.storedDraggedVariables = action.payload;
    },
    setPdfDraggedVariables: (state, action) => {
      state.pdfDraggedVariables = action.payload;
    },
  },
});

export const { setStoredDraggedVariables, setPdfDraggedVariables } =
  variablesSlice.actions;

export default variablesSlice.reducer;
