import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { Badge, Table } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  addFieldToArrayObject,
  checkFieldAvailability,
} from "../../utils/array";
import { setExcelData } from "../../redux-slice/filesSlice";
import {
  addUser,
  getInvitationWithStatusById,
  updateUser,
} from "../../api/api";
import { cancel_icon, save_icon } from "../../assets/icons";
import { setInvitationWithUsers } from "../../redux-slice/statusSlice";
import { listenToInvitationData } from "../../utils/firebase-database";
import { get, onValue, push, ref, set, update } from "firebase/database";
import { database } from "../../config/firebase";
import { usePDFContext } from "../../context/Context";
import toast from "react-hot-toast";

const GuestData = ({ currentRow, invitationWithUsers }) => {
  const { excelData } = useSelector((state) => state.files);
  // const { invitationId, setShort_id } = usePDFContext();

  // const { invitationWithUsers } = useSelector((state) => state.status);
  const [current, setCurrent] = useState(0);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLocation = location.pathname;
  const params = useParams();
  const invitationId = params?.invitationId;
  const [isSortFailed, setIsSortFailed] = useState(true);
  const [guestData, setGuestData] = useState([]);
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [invitationShortId, setInvitationShortId] = useState();
  const [isNewGuestRow, setIsNewGuestRow] = useState(false);
  const [newGuestRow, setNewGuestRow] = useState([]);

  // useEffect(() => {
  //   const fetchInvitation = async () => {
  //     if (invitationId) {
  //       try {
  //         const invitation = await getInvitationWithStatusById(invitationId);
  //         setInvitationShortId(invitation.data.result._id);
  //       } catch (error) {
  //         console.error("Error fetching invitation:", error);
  //       }
  //     }
  //   };

  //   fetchInvitation();
  // }, [invitationId]);

  useEffect(() => {
    if (invitationId) {
      const usersId = invitationWithUsers?.users?.map((user) => user?._id);
      usersId?.forEach((userId) => {
        const userStatusRef = ref(
          database,
          `invitations/${invitationId}/users/${userId}`
        );
        const unsubscribe = onValue(userStatusRef, (snapshot) => {
          const status = snapshot.val();
          if (status) {
            // Update the guest data with the new status for the current user
            setGuestData((prevGuestData) =>
              prevGuestData.map((guest) => {
                return guest.userId === userId
                  ? { ...guest, status: status.status }
                  : guest;
              })
            );
          }
        });
        return () => unsubscribe();
      });
    }
  }, [invitationId]);

  const updateGuestData = () => {
    let clonedExcelData = JSON.parse(JSON.stringify(excelData));
    clonedExcelData.guestData = addFieldToArrayObject(
      clonedExcelData.guestData,
      "isUpdating",
      "false"
    );
    dispatch(setExcelData(clonedExcelData));
    setGuestData(clonedExcelData.guestData);
  };

  // Sorting the guest data based on the status
  const sortGuestData = (type) => {
    if (type === "status") {
      let clonedGuestData = JSON.parse(JSON.stringify(guestData));
      // Toggle the sorting order
      setIsSortFailed(!isSortFailed);
      const sortedByStatus = clonedGuestData?.sort((a, b) => {
        if (a.status === "failed" && b.status === "success") {
          return isSortFailed ? -1 : 1; // Toggle the sorting order
        } else if (a.status === "success" && b.status === "failed") {
          return isSortFailed ? 1 : -1; // Toggle the sorting order
        } else {
          return 0; // leave the order unchanged
        }
      });
      clonedGuestData = [...sortedByStatus];
      setGuestData(clonedGuestData);
    }
  };

  const handleUpdateUser = async (user) => {
    const _id = user._id;
    const details = {};
    Object.entries(user).map((entry) => {
      if (
        !(
          entry[0] === "isUpdating" ||
          entry[0] === "_id" ||
          entry[0] === "status"
        )
      ) {
        Object.assign(details, { [entry[0]]: entry[1] });
      }
    });
    const body = { details, _id, status: "pending" };
    const isUserUpdated = await updateUser(body);
    return isUserUpdated;
  };

  const addNewUser = async (user) => {
    const { status, isUpdating, ...filteredUser } = user;

    const payload = {
      invitation_id: invitationId,
      details: filteredUser,
      status: "pending",
      isDeleted: false,
    };
    const isUserAdded = await addUser(payload);

    return isUserAdded;
  };

  const handleEdit = async (i) => {
    let findedObject = { ...guestData[i] };
    if (findedObject.isUpdating === "true") {
      const isUserUpdated = await handleUpdateUser(findedObject);
      if (isUserUpdated) {
        findedObject.status = "pending";
        let clonedExcelData = JSON.parse(JSON.stringify(excelData));
        clonedExcelData.guestData[i] = findedObject;
        dispatch(setExcelData(clonedExcelData));
      }
    }
    findedObject = {
      ...findedObject,
      isUpdating: findedObject.isUpdating === "true" ? "false" : "true",
    };
    updateInvitationWithUsers(findedObject);
    setGuestData([
      ...guestData.slice(0, i), // Keep the elements before the index 'i'
      findedObject, // Insert the updated 'findedObject' at index 'i'
      ...guestData.slice(i + 1), // Keep the elements after the index 'i'
    ]);
  };

  const handleUpdateGuestDetails = (key, value, index) => {
    let clonedGuestData = JSON.parse(JSON.stringify(guestData));
    clonedGuestData[index][key] = value;
    // dispatch(setExcelData(clonedExcelData));
    setGuestData(clonedGuestData);
  };

  const handleGuestData = () => {
    const clonedGuestData = JSON.parse(JSON.stringify(excelData?.guestData));
    const filteredGuestData = clonedGuestData?.map((guest, i) => {
      delete guest?._id;
      delete guest?.status;
      delete guest?.isUpdating;
      return guest;
    });
    filteredGuestData?.length > 0 && setGuestData(filteredGuestData);
  };

  const handleExcelHeaders = () => {
    const clonedExcelHeaders = JSON.parse(
      JSON.stringify(excelData?.excelHeaders)
    );
    if (currentLocation === `/invitation-status/${invitationId}`) {
      setExcelHeaders(clonedExcelHeaders);
    } else {
      const filterExcelHeaders = clonedExcelHeaders?.filter((header, i) => {
        if (header !== "_id" && header !== "status") {
          return true;
        }
      });
      setExcelHeaders(filterExcelHeaders);
    }
  };

  const updateInvitationWithUsers = (guest) => {
    const clonedInvitationWithUsers = JSON.parse(
      JSON.stringify(invitationWithUsers)
    );
    const clonedGuest = JSON.parse(JSON.stringify(guest));
    const status = clonedGuest?.status || "pending";
    const index = clonedInvitationWithUsers?.users.findIndex(
      (user) => user?._id === guest?._id
    );
    if (index !== -1) {
      // Check if the user exists
      delete clonedGuest?._id;
      delete clonedGuest?.isUpdating;
      delete clonedGuest?.status;
      clonedInvitationWithUsers.users[index].details = clonedGuest;
      clonedInvitationWithUsers.users[index].status = status;
      dispatch(setInvitationWithUsers(clonedInvitationWithUsers));
    } else {
      console.error("User not found for updating:", guest);
    }
  };

  useEffect(() => {
    setInterval(() => {
      setCurrent(current + 2);
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      excelData?.guestData &&
      currentLocation === `/invitation-status/${invitationId}`
    ) {
      const clonedGuestData = [...excelData?.guestData];

      !checkFieldAvailability(clonedGuestData, "isUpdating")
        ? updateGuestData()
        : setGuestData(clonedGuestData);
    } else if (excelData?.guestData?.length > 0) {
      handleGuestData();
    }
  }, [excelData]);

  useEffect(() => {
    excelData?.guestData && handleExcelHeaders();
  }, [excelData]);

  const addNewGuestRow = () => {
    const newGuest = excelHeaders.reduce((acc, header) => {
      if (
        header !== "userId" &&
        header !== "_id" &&
        header !== "status" &&
        header !== "isUpdating"
      ) {
        acc[header] = ""; // Initialize with empty string or any default value
      }
      return acc;
    }, {});

    // Add 'status' and 'isUpdating' fields at the end
    newGuest.status = "pending";
    newGuest.isUpdating = "false";
    setIsNewGuestRow(true);
    setNewGuestRow([...newGuestRow, newGuest]);

    // setGuestData([...guestData, newGuest]);
  };

  const handleNewGuestChange = (index, key, value) => {
    const clonedNewGuestRow = JSON.parse(JSON.stringify(newGuestRow));
    clonedNewGuestRow[index][key] = value;
    setNewGuestRow(clonedNewGuestRow);
  };

  const handleNewGuestSave = async (index) => {
    const clonedNewGuestRow = JSON.parse(JSON.stringify(newGuestRow));
    const newGuest = clonedNewGuestRow[index];
    console.log("newGuest", newGuest);

    const isUserAdded = await addNewUser(newGuest);

    console.log("isUserAdded", isUserAdded);
    if (isUserAdded) {
      newGuest.status = "pending";

      const invitationRef = ref(
        database,
        `invitations/${invitationId}/guestData`
      );

      // Push the new guest into the guestData array
      const newGuestRef = push(invitationRef);
      await set(newGuestRef, isUserAdded);

      setNewGuestRow(clonedNewGuestRow);
      setIsNewGuestRow(false);
      toast.success("Guest added successfully");

      setGuestData([...guestData, newGuest]);
      setNewGuestRow([]);
    }
  };

  const handleNewGuestCancel = (index) => {
    const clonedNewGuestRow = JSON.parse(JSON.stringify(newGuestRow));
    clonedNewGuestRow.splice(index, 1);
    setNewGuestRow(clonedNewGuestRow);
  };

  return (
    <>
      <table className="custom-table" bordered hover>
        <thead>
          <tr>
            {excelHeaders?.length > 0 &&
              excelHeaders
                ?.filter((header) => header !== "userId")
                ?.map((header, index) => {
                  return (
                    <React.Fragment key={`header.${index}`}>
                      {index === 0 && <th>#</th>}
                      {header !== "_id" &&
                        header !== "isUpdating" &&
                        (header !== "status" ? (
                          <th>
                            <div className="d-flex align-items-center justify-content-center ">
                              <span className="">{header}</span>
                            </div>
                          </th>
                        ) : (
                          <th>
                            <div className="d-flex align-items-center justify-content-center ">
                              <span className="me-1">{header}</span>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  sortGuestData("status");
                                }}
                              >
                                &#8645;
                              </span>
                            </div>
                          </th>
                        ))}
                    </React.Fragment>
                  );
                })}
            {currentLocation === `/invitation-status/${invitationId}` && (
              <th>
                <div className="d-flex align-items-center justify-content-center ">
                  <span className="me-1">Edit</span>
                </div>
              </th>
            )}
          </tr>
        </thead>
        {/* <tbody>
        {guestData?.length > 0 &&
          guestData?.map((guestDetails, index) => {
            return (
              <tr
                key={`invitation-row-${index}`}
                className={index === currentRow ? "active-row" : ""}
              >
                <td>{index + 1}</td>
                {Object.values(guestDetails).length > 0 &&
                  Object.values(guestDetails).map(
                    (invitation, i) =>
                      Object.keys(guestDetails)[i] !== "_id" &&
                      (currentLocation ===
                      `/invitation-status/${invitationId}` ? (
                        Object.keys(guestDetails)[i] !== "status" &&
                        i !== Object.values(guestDetails).length - 1 ? (
                          <td
                            key={`invitation-data-${index}.${i}`}
                            className="row-data"
                          >
                            <div className="d-flex align-items-center justify-content-center ">
                              {guestData[index].isUpdating === "true" ? (
                                <div className="d-inline-block">
                                  <input
                                    type="text"
                                    className="w-100"
                                    value={invitation}
                                    onChange={(e) => {
                                      handleUpdateGuestDetails(
                                        Object.keys(guestDetails)[i],
                                        e.target.value,
                                        index
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                <span className="ms-1">{invitation}</span>
                              )}
                            </div>
                          </td>
                        ) : Object.keys(guestDetails)[i] !== "isUpdating" ? (
                          <td
                            key={`invitation-data-${index}.${i}`}
                            className="row-data"
                          >
                            <Badge
                              pill
                              className={`py-2 px-3 d-block text-capitalize fw-500 bg-${
                                invitation.toLowerCase() === "success"
                                  ? "success  text-white"
                                  : invitation.toLowerCase() === "failed"
                                  ? "danger  text-white"
                                  : "warning text-dark"
                              }                          
                          `}
                            >
                              <div className="process">{invitation}</div>
                            </Badge>
                          </td>
                        ) : (
                          <td>

                          
                            <div className="d-flex align-items-center justify-content-center ">
                              {currentLocation ===
                                `/invitation-status/${invitationId}` &&
                                (guestData[index]?.status !== "success" ? (
                                  invitation === "true" ? (
                                    <span
                                      aria-disabled
                                      className="cursor-pointer prevent-select"
                                      onClick={() => {
                                        handleEdit(index);
                                      }}
                                    >
                                      
                                      {save_icon()}
                                    </span>
                                  ) : (
                                    <span
                                      className="cursor-pointer prevent-select"
                                      onClick={() => {
                                        handleEdit(index);
                                      }}
                                    >
                                       &#9998;
                                    </span>
                                  )
                                ) : (
                                  <span
                                    className="prevent-select"
                                    style={{ color: "green" }}
                                  >
                                    &#10003; 
                                  </span>
                                ))}
                            </div>
                          </td>
                        )
                      ) : (
                        <td
                          key={`invitation-data-${index}.${i}`}
                          className="row-data"
                        >
                          <div className="d-flex align-items-center justify-content-center ">
                            <span className="ms-1">{invitation}</span>
                          </div>
                        </td>
                      ))
                  )}
              </tr>
            );
          })}
      </tbody> */}
        <tbody>
          {guestData?.length > 0 &&
            guestData?.map((guestDetails, index) => {
              // Remove the userId key from guestDetails
              const { userId, ...guestWithoutUserId } = guestDetails;
              return (
                <tr
                  key={`invitation-row-${index}`}
                  className={index === currentRow ? "active-row" : ""}
                >
                  <td>{index + 1}</td>
                  {Object.values(guestWithoutUserId).length > 0 &&
                    Object.values(guestWithoutUserId).map(
                      (invitation, i) =>
                        Object.keys(guestWithoutUserId)[i] !== "_id" &&
                        (currentLocation ===
                        `/invitation-status/${invitationId}` ? (
                          Object.keys(guestWithoutUserId)[i] !== "status" &&
                          i !== Object.values(guestWithoutUserId).length - 1 ? (
                            <td
                              key={`invitation-data-${index}.${i}`}
                              className="row-data"
                            >
                              <div className="d-flex align-items-center justify-content-center ">
                                {guestData[index].isUpdating === "true" ? (
                                  <div className="d-inline-block">
                                    <input
                                      type="text"
                                      className="w-100"
                                      value={invitation}
                                      onChange={(e) => {
                                        handleUpdateGuestDetails(
                                          Object.keys(guestWithoutUserId)[i],
                                          e.target.value,
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <span className="ms-1">{invitation}</span>
                                )}
                              </div>
                            </td>
                          ) : Object.keys(guestWithoutUserId)[i] !==
                            "isUpdating" ? (
                            <td
                              key={`invitation-data-${index}.${i}`}
                              className="row-data"
                            >
                              <Badge
                                pill
                                className={`py-2 px-3 d-block text-capitalize fw-500 bg-${
                                  invitation.toLowerCase() === "success"
                                    ? "success  text-white"
                                    : invitation.toLowerCase() === "alternate"
                                    ? "info text-dark"
                                    : invitation.toLowerCase() === "failed"
                                    ? "danger  text-white"
                                    : "warning text-dark"
                                }`}
                              >
                                <div className="process">{invitation}</div>
                              </Badge>
                            </td>
                          ) : (
                            <td>
                              <div className="d-flex align-items-center justify-content-center ">
                                {currentLocation ===
                                  `/invitation-status/${invitationId}` &&
                                  (guestData[index]?.status !== "success" ? (
                                    invitation === "true" ? (
                                      <span
                                        aria-disabled
                                        className="cursor-pointer prevent-select"
                                        onClick={() => {
                                          handleEdit(index);
                                        }}
                                      >
                                        {save_icon()}
                                      </span>
                                    ) : (
                                      <span
                                        className="cursor-pointer prevent-select"
                                        onClick={() => {
                                          handleEdit(index);
                                        }}
                                      >
                                        &#9998;
                                      </span>
                                    )
                                  ) : (
                                    <span
                                      className="prevent-select"
                                      style={{ color: "green" }}
                                    >
                                      &#10003;
                                    </span>
                                  ))}
                              </div>
                            </td>
                          )
                        ) : (
                          <td
                            key={`invitation-data-${index}.${i}`}
                            className="row-data"
                          >
                            <div className="d-flex align-items-center justify-content-center ">
                              <span className="ms-1">{invitation}</span>
                            </div>
                          </td>
                        ))
                    )}
                </tr>
              );
            })}

          {/* {newGuestRow?.length > 0 &&
          newGuestRow.map((guest, index) => (
            <tr key={`new-guest-row-${index}`}>
              <td>{guestData.length + index + 1}</td>
              {Object.entries(guest).map(([key, value], i) => (
                <td key={`new-guest-row-data-${index}.${i}`}>
                  {key === "status" ? (
                    <Badge
                      pill
                      className={`py-2 px-3 bg-warning text-dark fw-500 `}
                    >
                      {value}
                    </Badge>
                  ) : key === "isUpdating" ? (
                    <>
                      {!isNewGuestRow ? (
                        <span
                          className="cursor-pointer prevent-select"
                          onClick={() => {
                            handleEdit(index);
                          }}
                        >
                          &#9998; 
                        </span>
                      ) : (
                        <button
                          onClick={() => {
                            handleNewGuestSave(index);
                          }}
                        >
                          Save
                        </button>
                      )}
                    </>
                  ) : (
                    <input
                      type="text"
                      value={value}
                      onChange={(e) =>
                        handleNewGuestChange(index, key, e.target.value)
                      }
                    />
                  )}
                </td>
              ))}
            </tr>
          ))} */}

          {newGuestRow?.length > 0 &&
            newGuestRow.map((guest, newIndex) => {
              const dynamicIndex = guestData.length + newIndex; // Calculate the dynamic index

              return (
                <tr key={`new-guest-row-${dynamicIndex}`}>
                  <td>{dynamicIndex + 1}</td>
                  {Object.entries(guest).map(([key, value], i) => (
                    <td
                      key={`new-guest-row-data-${dynamicIndex}.${i}`}
                      className="text-center"
                    >
                      {key === "status" ? (
                        <Badge
                          pill
                          className={`py-2 px-3 bg-warning text-dark fw-500 `}
                        >
                          {value}
                        </Badge>
                      ) : key === "isUpdating" ? (
                        <>
                          {!isNewGuestRow ? (
                            <span
                              className="cursor-pointer prevent-select"
                              onClick={() => {
                                handleEdit(dynamicIndex); // Use the dynamic index here
                              }}
                            >
                              &#9998; {/* Edit icon */}
                            </span>
                          ) : (
                            <>
                              <div className="d-flex align-items-center justify-content-center gap-2">
                                <span
                                  className="cursor-pointer prevent-select text-center"
                                  onClick={() => {
                                    handleNewGuestSave(newIndex); // Use newIndex for saving
                                  }}
                                >
                                  {save_icon()}
                                </span>
                                <span
                                  className="cursor-pointer prevent-select text-center"
                                  onClick={() => {
                                    handleNewGuestCancel(newIndex); // Use newIndex for saving
                                  }}
                                >
                                  {cancel_icon()}
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleNewGuestChange(
                                newIndex,
                                key,
                                e.target.value
                              )
                            }
                          />
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className="d-flex justify-content-center mt-3">
        <button className="btn btn-primary" onClick={addNewGuestRow}>
          Add New Guest
        </button>
      </div>
    </>
  );
};

export default GuestData;
