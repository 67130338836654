import "bootstrap/dist/css/bootstrap.min.css";
import CustomRoutes from "./customRoutes";
import "./assets/styles/global.scss";
import { pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import { getNgrokBaseUrl } from "./api/api";
import { setInLocalStorage } from "./utils/url";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // getBackendBaseUrl();
  }, []);

  const getBackendBaseUrl = async () => {
    try {
      let retrying = 0;
      let baseUrl = "";
      const innerFunction = async () => {
        try {
          const res = await getNgrokBaseUrl();
          baseUrl = res?.data?.result?.apiInfo?.baseUrl || "";
          if (!baseUrl && retrying < 3) {
            retrying++;
            await innerFunction();
          }
        } catch (error) {
          if (!baseUrl && retrying < 3) {
            retrying++;
            await innerFunction();
          }
          console.log(error);
        }
      };
      await innerFunction();
      setInLocalStorage(process.env.REACT_APP_NGROK_URL_KEY, baseUrl);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="app">{loading ? <div>Loading</div> : <CustomRoutes />}</div>
  );
}

export default App;
