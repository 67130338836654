import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const ExcelModel = (props) => {
  const { incompleteRows } = props;

  const navigate = useNavigate();
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Incomplete Excel
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0">
          Some rows in Excel contain incomplete information as follows.
        </p>

        <div className="d-flex gap-2">
          <p className="mb-0">Row no:</p>
          <p className="mb-0 text-secondary">{incompleteRows}</p>
        </div>

        <b>Please verify and re-upload it.</b>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={props.onHide}>Close</Button>
        <Button
          onClick={() => {
            props.onHide();
            navigate("/variable")
          }}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExcelModel;
