import React from "react";
import NavbarComponent from "../components/navbar2";
import SignInForm from "../components/signInForm/index.js";

const SignIn = () => {
  return (
    <div className="bg-yellow h-100vh d-flex flex-column">
      <NavbarComponent />
      <SignInForm />
    </div>
  );
};

export default SignIn;
