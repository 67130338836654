import React from "react";

const CallToAction = () => {
  return (
    <section className="py-5 bg-gradient-primary text-white">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-8 text-center text-lg-start">
            <h2 className="display-4 fw-bold mb-3">
              Ready to Automate Your Invitations?
            </h2>
            <p className="lead mb-0">
              Join thousands of users sending WhatsApp invitations effortlessly.
            </p>
          </div>
          <div className="col-lg-4 text-center text-lg-end mt-4 mt-lg-0">
            <a href="/send-invitation" className="btn btn-light btn-lg px-4">
              <i className="fab fa-whatsapp me-2"></i>Start Sending Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
