import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// production
const firebaseConfig = {
  apiKey: "AIzaSyCIIysV2Bzse9_nLAk8U1eobqgqmPeFfWY",
  authDomain: "rapid-sender-4d79d.firebaseapp.com",
  databaseURL: "https://rapid-sender-4d79d-default-rtdb.firebaseio.com",
  projectId: "rapid-sender-4d79d",
  storageBucket: "rapid-sender-4d79d.firebasestorage.app",
  messagingSenderId: "425782955827",
  appId: "1:425782955827:web:05f68ae306f1f4cd75f846",
  measurementId: "G-FQD7MCL41Y",
};

// staging
// const firebaseConfig = {
//   apiKey: "AIzaSyBoZi-2W6LTjOz7ftYDwEmCrJlcjlzfUMY",
//   authDomain: "rapid-sender-demo.firebaseapp.com",
//   databaseURL: "https://rapid-sender-demo-default-rtdb.firebaseio.com",
//   projectId: "rapid-sender-demo",
//   storageBucket: "rapid-sender-demo.firebasestorage.app",
//   messagingSenderId: "955391693524",
//   appId: "1:955391693524:web:f8293430db642e857c6141",
//   measurementId: "G-3QBYE8W0SP",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get database reference
const database = getDatabase(app);
const db = getFirestore(app);

export { database, db, collection, addDoc };
